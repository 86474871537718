import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'vat';

const vatService = {
  getVat: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/vat`,
      data,
    });
  },

  createVat: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateVat: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteVat: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },
};

export default vatService;
