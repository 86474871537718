import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { useLoader } from '../../provider/LoaderProvider';
import useUsers from '../../context/UsersContext';
import taskListResourcePlannerService from '../../service/taskListResourcePlanner.service';
import EventChip from './EventChip';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import { CircularStaticProgressUI } from '../Interface';
import ModifyDialog from './ModifyDialog';
import taskListService from '../../service/taskList.service';

const TaskListPlannerPage = () => {
  const { getUser, user } = useUsers();
  const { showLoader, hideLoader } = useLoader();
  const [tasks, setTasks] = useState({ resources: [], events: [], backgroundEvents: [] });
  const [dateRange, setDateRange] = useState({});
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [isModifyDialogOpen, setIsModifyDialogOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});

  const [timeInterval, setTimeInterval] = useState({});
  const [intervalButtons, setIntervalButtons] = useState({});
  const [calendarViews, setCalendarViews] = useState({});
  const [selectedView, setSelectedView] = useState('');

  const slotIntervals = {
    day: { days: 1 },
    week: { weeks: 1 },
    month: { months: 1 },
  };

  const calendarDayViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [{ day: 'numeric', weekday: 'long' }],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ day: 'numeric', weekday: 'short' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'short' }, { day: 'numeric' }],
    },
  };

  const calendarWeekViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
  };

  const calendarMonthViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'long' }],
    },
  };

  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    setTimeInterval(slotIntervals.day);
    setCalendarViews(calendarDayViews);

    getProjects();
    getEvents();
    if (!Object.keys(user).length) getUser();
  }, []);

  useEffect(() => {
    setIntervalButtons(generateButtons(selectedView));
  }, [selectedView]);

  const getProjects = () => {
    showLoader();
    taskListResourcePlannerService
      .getProjects()
      .then((data) => {
        setTasks((prevState) => ({ ...prevState, resources: data }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const defaultStyles = {
    backgroundColor: '#8EE997',
    textColor: 'rgba(22, 163, 74, 1)',
  };

  const getEvents = () => {
    //showLoader();
    taskListResourcePlannerService
      .getEvents()
      .then((data) => {
        const events = data.map((event) => {
          return {
            ...event,
            ...defaultStyles,
          };
        });
        setTasks((prevState) => ({ ...prevState, events }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleDatesSet = (dateInfo) => {
    const startDate = dayjs(dateInfo.start).startOf('day');
    const endDate = dayjs(dateInfo.end).endOf('day');

    if (
      !dateRange.startDate ||
      !dateRange.endDate ||
      !startDate.isSame(dateRange.startDate) ||
      !endDate.isSame(dateRange.endDate)
    ) {
      setDateRange({ startDate, endDate });
    }

    if (selectedView !== dateInfo.view.type) setSelectedView(dateInfo.view.type);
  };

  const timeIntervalButtons = {
    day: {
      text: 'Nap',
      click: () => {
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);
      },
    },
    week: {
      text: 'Hét',
      click: () => {
        setTimeInterval(slotIntervals.week);
        setCalendarViews(calendarWeekViews);
      },
    },
    month: {
      text: 'Hónap',
      click: () => {
        setTimeInterval(slotIntervals.month);
        setCalendarViews(calendarMonthViews);
      },
    },
  };

  const generateButtons = (viewName) => {
    let retVal = {};
    switch (viewName) {
      case 'resourceTimelineDay':
        retVal = 'day';
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);

        break;
      case 'resourceTimelineWeek':
        retVal = 'day,week';
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);

        break;
      case 'resourceTimelineMonth':
        retVal = 'day,week,month';
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);

        break;
      case 'resourceTimelineYear':
        retVal = 'day,week,month';
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);

        break;
    }
    return retVal;
  };

  const updateRelatedEventsColors = (event) => {
    const lineId = event.extendedProps.lineId;
    setTasks((prevState) => {
      const updatedEvents = prevState.events.map((e) => {
        if (e.lineId === lineId) {
          return { ...e, backgroundColor: 'rgba(254, 215, 170, 0.6)' };
        }
        return e;
      });
      return { ...prevState, events: updatedEvents };
    });
  };

  const restoreOriginalColors = () => {
    setTasks((prevState) => {
      const restoredEvents = prevState.events.map((event) => {
        return { ...event, ...defaultStyles };
      });
      return { ...prevState, events: restoredEvents };
    });
  };

  const onEventDragStart = (eventInfo) => {
    updateRelatedEventsColors(eventInfo.event);
  };

  const onEventResizeStart = (eventInfo) => {
    updateRelatedEventsColors(eventInfo.event);
  };

  const onEventDragStop = () => {
    restoreOriginalColors();
  };

  const onEventResizeStop = () => {
    restoreOriginalColors();
  };

  const updateEvent = (resizeInfo) => {
    const { event, revert } = resizeInfo;
    const resource = event.getResources()[0].id;

    const isTaskList = resource.startsWith('T');
    const resourceId = parseInt(resource.substring(1));

    if (!isTaskList) {
      addNotification({
        content: 'Az esemény nem helyezhető csoportra!',
        type: NotificationType.ERROR,
      });
      revert();
      return;
    }

    if (resourceId !== parseInt(event.id)) {
      addNotification({
        content: 'Az esemény nem helyezhető át',
        type: NotificationType.ERROR,
      });
      revert();
      return;
    }

    const duration = event.end ? dayjs(event.end).diff(dayjs(event.start), 'day') : null;
    const startDate = dayjs(event.start).toISOString();

    const updatedEventData = {
      startDate,
      duration,
    };

    showLoader();
    const id = event.id;

    taskListResourcePlannerService
      .updateEvent(updatedEventData, id)
      .then(() => {
        addNotification({
          content: 'Sikeres módosítás!',
          type: NotificationType.SUCCESS,
        });
        getEvents();
      })
      .catch(() => {
        revert();
      })
      .finally(() => {
        hideLoader();
      });
  };

  const projectInterval = tasks.resources.map((resource) => ({
    start: dayjs(resource.startDate).startOf('day').toISOString(),
    end: dayjs(resource.endDate).add(1, 'day').startOf('day').toISOString(),
    resourceId: resource.id,
    className: 'projectLine',
    projectLine: true,
    backgroundColor: '#6F9AD8',
  }));

  const isSelectedResource = (resourceId) => {
    return resourceId === selectedRowId;
  };

  const resourceColumns = [
    {
      field: '',
      headerContent: '',
      headerClassNames: 'col0',
    },
    {
      field: 'preparedness',
      headerContent: 'Készültség',
      headerClassNames: 'col preparednessCol',
      cellContent: function (arg) {
        const isParent = !arg.resource._resource.parentId;
        if (!isParent) {
          const preparedness = arg.resource._resource.extendedProps.preparedness;

          return (
            <div className="absolute top-0.5 -translate-x-1/2 left-2/4">
              <CircularStaticProgressUI value={preparedness} circleColor="#3183FE" size={35} />
            </div>
          );
        } else {
          const id = parseInt(arg.resource.id);
          const foundedItems = tasks.events.filter((item) => item.lineId === id);

          if (foundedItems.length > 0) {
            const totalPreparedness = foundedItems.reduce((sum, item) => sum + item.preparedness, 0);
            const averagePreparedness = Math.round(totalPreparedness / foundedItems.length);

            return (
              <div className="absolute top-0.5 -translate-x-1/2 left-2/4">
                <CircularStaticProgressUI value={averagePreparedness} circleColor="#3183FE" size={35} />
              </div>
            );
          } else {
            return '';
          }
        }
      },
      cellClassNames: (arg) => {
        const classNames = ['preparednessCol'];
        if (isSelectedResource(arg.resource.id)) {
          classNames.push('selected-row');
        }
        return classNames;
      },
    },
    {
      field: 'startDate',
      headerContent: 'Kezdés',
      headerClassNames: 'col startDateCol',
      cellContent: function (arg) {
        const isParent = !arg.resource._resource.parentId;
        if (!isParent) {
          const startDate = arg.resource._resource.extendedProps.startDate;
          return dayjs(startDate).format('YYYY.MM.DD');
        } else return '';
      },
      cellClassNames: (arg) => {
        const classNames = ['startDateCol'];
        if (isSelectedResource(arg.resource.id)) {
          classNames.push('selected-row');
        }
        return classNames;
      },
    },
    {
      field: 'endDate',
      headerContent: 'Befejezés',
      headerClassNames: 'col endDateCol',
      cellContent: function (arg) {
        const isParent = !arg.resource._resource.parentId;
        if (!isParent) {
          const { startDate, duration } = arg.resource._resource.extendedProps;

          return dayjs(startDate).add(duration, 'day').format('YYYY.MM.DD');
        } else return '';
      },
      cellClassNames: (arg) => {
        const classNames = ['endDateCol'];
        if (isSelectedResource(arg.resource.id)) {
          classNames.push('selected-row');
        }
        return classNames;
      },
    },
    {
      field: 'duration',
      headerContent: 'Időintervallum',
      headerClassNames: 'col durationCol',
      cellContent: function (arg) {
        const isParent = !arg.resource._resource.parentId;
        if (!isParent) {
          const duration = arg.resource._resource.extendedProps.duration;

          return `${duration} nap`;
        } else return '';
      },
      cellClassNames: (arg) => {
        const classNames = ['durationCol'];
        if (isSelectedResource(arg.resource.id)) {
          classNames.push('selected-row');
        }
        return classNames;
      },
    },
  ];

  const handleSelect = (selectInfo) => {
    setSelectedRowId(selectInfo.resource.id);
  };

  const handleResourceLabelClick = (resource) => {
    if (resource._resource.parentId) {
      const id = parseInt(resource._resource.id.substring(1));
      setSelectedValues((prev) => ({
        ...prev,
        name: resource._resource.title,
        id,
      }));
      setIsModifyDialogOpen(true);
    }
  };

  const getResourceLabelClassNames = (resource) => {
    let classes = [];
    if (resource.id === selectedRowId) {
      classes.push('selected-row');
    }

    if (!resource._resource.parentId) {
      classes.push('fc-res-group');
    }
    return classes;
  };

  const handleChangeName = () => {
    taskListService.updateTaskListName(selectedValues, selectedValues.id).then((data) => {
      setTasks((prevState) => {
        const updatedResources = prevState.resources.flatMap((resource) => {
          const updatedChildren = resource.children.map((child) => {
            const id = parseInt(child.id.substring(1));
            if (id === data.id) {
              return { ...child, title: data.name };
            }
            return child;
          });

          return { ...resource, children: updatedChildren };
        });

        return {
          ...prevState,
          resources: updatedResources,
        };
      });
      setIsModifyDialogOpen(false);
    });
  };

  return (
    <div className="taskListCalendar">
      <FullCalendar
        customButtons={timeIntervalButtons}
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        initialView="resourceTimelineMonth"
        resources={tasks.resources}
        locales={[huLocale]}
        locale="hu"
        select={handleSelect}
        slotLabelFormat={[
          { month: 'long', year: 'numeric', weekday: 'long' },
          { hour: 'numeric', minute: '2-digit' },
        ]}
        resourceOrder="visOrder"
        datesSet={handleDatesSet}
        events={[...tasks.events, ...tasks.backgroundEvents, ...projectInterval]}
        editable={true}
        nowIndicator={true}
        selectable={true}
        eventResizeStart={onEventResizeStart}
        eventDragStart={onEventDragStart}
        eventDragStop={onEventDragStop}
        eventResizeStop={onEventResizeStop}
        eventResize={updateEvent}
        eventDrop={updateEvent}
        eventContent={(eventInfo) => {
          if (eventInfo.event.display !== 'background') {
            return <EventChip eventInfo={eventInfo} defaultStyles={defaultStyles} />;
          }
        }}
        headerToolbar={{
          left: `prev,next,today ${intervalButtons}`,
          center: 'title',
          right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth,resourceTimelineYear',
        }}
        views={calendarViews}
        slotDuration={timeInterval}
        resourceAreaWidth="25%"
        resourceAreaHeaderContent="Erőforrás"
        scrollTimeReset={false}
        resourceAreaColumns={resourceColumns}
        resourceLabelClassNames={(data) => getResourceLabelClassNames(data.resource)}
        resourceLabelContent={(data) => {
          const resource = data.resource;
          const classNames = getResourceLabelClassNames(resource);

          return (
            <span
              className={classNames.join(' ')}
              onDoubleClick={() => handleResourceLabelClick(resource)}
              style={{ cursor: 'pointer' }}
            >
              {resource.title}
            </span>
          );
        }}
      />
      <ModifyDialog
        open={isModifyDialogOpen}
        handleClose={() => setIsModifyDialogOpen(false)}
        values={{ selectedValues, setSelectedValues }}
        handleChangeName={handleChangeName}
      />
    </div>
  );
};

export default TaskListPlannerPage;
