import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import SimpleTaskDialog from './SimpleTaskDialog';
import { Popover } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ButtonUI } from '../Interface';
import simpletaskService from '../../service/simpletask.service';
import crmTaskService from '../../service/crmTask.service';
import projectService from '../../service/project.service';
import CrmTaskDialog from '../CrmTask/CrmTaskDialog';

const ProjectHistory = ({ selectedValues, setSelectedValues, disabled, setRefetchTable }) => {
  const [selectedRow, setSelectedRow] = useState({});
  const [rows, setRows] = useState([]);
  const [isSimpleTaskDialogOpen, setIsSimpleTaskDialogOpen] = useState(false);
  const [isCrmTaskDialogOpen, setIsCrmTaskDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [taskChanged, setTaskChanged] = useState(false);

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (selectedValues.histories && selectedValues.histories.length > 0) {
      setRows(selectedValues.histories);
    } else {
      setRows([]);
    }
  }, [selectedValues.histories]);

  useEffect(() => {
    if (taskChanged) {
      getProjectById();
      setRefetchTable(true);
    }
  }, [taskChanged]);

  const getProjectById = () => {
    if (selectedValues.projectId) {
      projectService.getProjectById(selectedValues.projectId).then((data) => {
        setSelectedValues(data);
        setTaskChanged(false);
      });
    }
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (row) => {
    if (row.taskType === 'Feladat') {
      getSimpleTaskById(row);
    } else if (row.taskType === 'Esemény') {
      getCrmTaskById(row);
    }
  };

  const getSimpleTaskById = (row) => {
    simpletaskService.getTaskById(row.id).then((data) => {
      setSelectedRow(data);
      setIsSimpleTaskDialogOpen(true);
    });
  };

  const getCrmTaskById = (row) => {
    crmTaskService.getTaskById(row.id).then((data) => {
      setSelectedRow(data);
      setIsCrmTaskDialogOpen(true);
    });
  };

  return (
    <div className="max-h-[400px] overflow-y-scroll overflow-x-hidden custom-scrollbar -mt-3">
      <ButtonUI
        text="Új felvitel"
        className="bg-success mb-2"
        size="sm"
        onClick={handlePopoverOpen}
        disabled={disabled}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div className="p-4 flex gap-2">
          <ButtonUI text="Új esemény" className="bg-warning" size="sm" onClick={() => setIsCrmTaskDialogOpen(true)} />
          <ButtonUI
            text="Új feladat"
            className="bg-warning"
            size="sm"
            onClick={() => setIsSimpleTaskDialogOpen(true)}
          />
        </div>
      </Popover>
      <table className="border w-full">
        <thead className="bg-tableHeaderColor">
          <tr className="grid grid-cols-7">
            <th className="border p-3">Kezdő időpont</th>
            <th className="border p-3">Objektum</th>
            <th className="border p-3 col-span-2">Feladat neve</th>
            <th className="border p-3">Típus</th>
            <th className="border p-3">Tulajdonos</th>
            <th className="border p-3">Megtekint</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr
              key={index}
              className={
                index % 2 === 0
                  ? 'bg-gray-100 grid grid-cols-7 hover:bg-[#EDF4FB] cursor-pointer'
                  : 'grid grid-cols-7 hover:bg-[#EDF4FB] cursor-pointer'
              }
              onDoubleClick={() => handleOpen(row)}
            >
              <td className="border py-2 text-center">{dayjs(row.startDate).format('YYYY.MM.DD. HH:mm')}</td>
              <td className="border py-2 text-center">{row.taskType}</td>
              <td className="border py-2 text-center col-span-2">{row.name}</td>
              <td className="border py-2 text-center">{row?.type?.itemName}</td>
              <td className="border py-2 text-center">{row?.user?.userName}</td>
              <td className="border py-2 text-center">
                <RemoveRedEyeIcon color="black" onClick={() => handleOpen(row)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <SimpleTaskDialog
        open={isSimpleTaskDialogOpen}
        handleClose={() => setIsSimpleTaskDialogOpen(false)}
        task={selectedRow}
        setSelectedRow={setSelectedRow}
        setRows={setRows}
        setTaskChanged={setTaskChanged}
        selectedValues={selectedValues}
        parentData={{ projectId: selectedValues.projectId }}
      />
      <CrmTaskDialog
        open={isCrmTaskDialogOpen}
        handleClose={() => setIsCrmTaskDialogOpen(false)}
        task={selectedRow}
        setSelectedRow={setSelectedRow}
        setTaskChanged={setTaskChanged}
        selectedValues={selectedValues}
        parentData={{ projectId: selectedValues.projectId, companyId: selectedValues.companyId }}
      />
    </div>
  );
};

export default ProjectHistory;
