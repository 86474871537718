import React from 'react';
import { IconButton } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';

const History = (props) => {
  return (
    <IconButton {...props}>
      <HistoryIcon sx={{ fontSize: '2rem' }} />
    </IconButton>
  );
};

export default History;
