import React, { useRef, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { isEmpty } from 'lodash';
import Gantt from 'frappe-gantt';
import moment from 'moment';
import dayjs from 'dayjs';
import {
  validateDependencies,
  collectRelatedRecords,
  collectRelatedRecordsData,
  mapDependencies,
} from '../relationHelper';
import { copyHourMinute } from '../../../utils/dateTimeHelper';
// import './GanttChart.css';

const DependenciesGantt = ({ task, productionOrdersWithSapProDependencies, updateBatch }) => {
  const ganttContainerRef = useRef(null);
  const ganttInstanceRef = useRef(null);
  const tasksRef = useRef([]);
  const [tasks, setTasks] = useState([]);
  const changesRef = useRef([]);

  const relatedIds = collectRelatedRecords(productionOrdersWithSapProDependencies, task.id);

  const filteredDepStructure = relatedIds
    .map((id) => productionOrdersWithSapProDependencies.find((item) => item.id === id))
    .filter((item) => item !== undefined);

  useEffect(() => {
    const newTasks = mapDependencies(filteredDepStructure);
    setTasks(newTasks);
    tasksRef.current = newTasks; // Update the ref with the latest tasks
  }, [productionOrdersWithSapProDependencies, task.id]);

  const updateTasks = (changes) => {
    if (changes[0]) {
      const updatedEventData = {
        id: changes[0]?.task?.itemId,
        startDate: changes[0]?.start,
        endDate: changes[0]?.end,
        machineId: changes[0]?.task?.resourceId,
        different: changes[0]?.different,
      };

      updateBatch(updatedEventData);

      setTasks((prevTasks) => {
        const newTasks = prevTasks.map((task) => {
          const change = changes.find((change) => change.task.id === task.id);
          return change
            ? {
                ...task,
                start: moment(change.start).format('YYYY-MM-DD'),
                end: moment(change.end).format('YYYY-MM-DD'),
              }
            : task;
        });
        tasksRef.current = newTasks; // Update the ref with the new tasks
        if (ganttInstanceRef.current) {
          ganttInstanceRef.current.refresh(newTasks);
        }
        return newTasks;
      });
    }
  };

  const handleDateChange = () => {
    const changes = [...changesRef.current];
    changesRef.current = []; // Clear changes

    let originalTask = filteredDepStructure.find((f) => f.id === changes[0].task.itemId);

    const relatedRecordsData = collectRelatedRecordsData(
      productionOrdersWithSapProDependencies,
      changes[0].task.itemId
    );
    const endDifferent = changes[0].different;

    const checkData = [];

    if (!isEmpty(relatedRecordsData)) {
      relatedRecordsData.forEach((e) => {
        const findedVData = tasksRef.current.find((v) => v.itemId === e.id);

        if (!e.isParent) {
          checkData.push({
            ...findedVData,
            _end: dayjs(findedVData._end).add(endDifferent, 'm').toDate(),
            _start: dayjs(findedVData._start).add(endDifferent, 'm').toDate(),
          });
        } else {
          checkData.push({
            ...findedVData,
          });
        }
      });
    }

    let vTask = tasksRef.current.find((v) => v.itemId === changes[0].task.itemId);

    const updatedTask = {
      ...vTask,
      _end: changes[0].end,
      _start: changes[0].start,
    };
    checkData.push(updatedTask);

    const checkObj = {
      task: updatedTask,
      _end: changes[0].end,
      _start: changes[0].start,
      different: endDifferent,
    };

    const parent = originalTask.dependencies.find((f) => f.parent)?.parent;
    if (parent) {
      const parentId = `${parent.docEntry}_${parent.lineNum}`;
      const parentOrderData = filteredDepStructure.find((f) => f.id === parentId);
      originalTask.parent = parentOrderData;
    }

    const valid = validateDependencies([checkObj], checkData, originalTask);
    if (valid) {
      updateTasks(changes);
    } else {
      // Revert the changes only if ganttInstance is not null
      if (ganttInstanceRef.current) {
        ganttInstanceRef.current.refresh(tasksRef.current);
      }
    }
  };

  useEffect(() => {
    if (ganttContainerRef.current) {
      const validTasks = tasks.filter((task) => {
        const isValid =
          task.start &&
          task.end &&
          moment(task.start, 'YYYY-MM-DD', true).isValid() &&
          moment(task.end, 'YYYY-MM-DD', true).isValid();
        if (!isValid) {
          console.warn('Invalid task date detected:', task);
        }
        return isValid;
      });

      if (validTasks.length > 0) {
        if (ganttInstanceRef.current) {
          ganttInstanceRef.current.refresh(validTasks);
        } else {
          const newGantt = new Gantt(ganttContainerRef.current, validTasks, {
            view_mode: 'Day',
            date_format: 'YYYY-MM-DD',
            /* custom_popup_html: (task) => {
              return `<div class="details-container">
                        <h5>${task.name}</h5>
                        <p>Start: ${moment(task.start).format('MMM DD, YYYY')}</p>
                        <p>End: ${moment(task.end).format('MMM DD, YYYY')}</p>
                      </div>`;
            }, */
            on_date_change: (task, start, end) => {
              const originalTask = productionOrdersWithSapProDependencies.find((f) => f.id === task.itemId);

              const startWithValidTime = copyHourMinute(originalTask.start, start).toDate();
              const endWithValidTime = copyHourMinute(originalTask.end, end).toDate();

              const different = dayjs(endWithValidTime).diff(originalTask.end, 'm');

              task._start = startWithValidTime;
              task._end = endWithValidTime;
              if (!changesRef.current.length) {
                changesRef.current.push({
                  task,
                  start: startWithValidTime,
                  end: endWithValidTime,
                  different,
                });
                setTimeout(handleDateChange, 100); // Debounce
              }
            },
          });
          ganttInstanceRef.current = newGantt;
        }
      } else {
        console.warn('No valid tasks to display');
      }
    }
  }, [tasks]);

  return (
    <div className="flex-grow h-full">
      {isEmpty(productionOrdersWithSapProDependencies) ? (
        <CircularProgress size={70} />
      ) : (
        <div ref={ganttContainerRef} id="gantt"></div>
      )}
    </div>
  );
};

export default DependenciesGantt;
