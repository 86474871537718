import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';

import useUsers from '../../context/UsersContext';
import HistoryDialog from '../History/HistoryDialog';
import { getDynVals } from '../../utils/DynHelper';
import projectService from '../../service/project.service';
import masterDataService from '../../service/masterData.service';
import { generateAutocompleteItems } from '../../utils/helper';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI } from '../Interface';
import { ProjectFields } from './ProjectFields';
import History from '../History/History';
import ProjectTabs from './ProjectTabs';

export const ProjectPage = ({ projectState, infoPanel, dialog }) => {
  const {
    setRefetchTable,
    selectedValues,
    setSelectedValues,
    viewField,
    setViewField,
    operatorsDisabled,
    setOperatorsDisabled,
    submenu,
    submenuNames,
    params,
    canEdit,
    setRowSelectionModel,
    dynProps,
    setDynProps,
    autoCompleteOptions,
    setAutoCompleteOptions,
    setLoading,
    dynLoading,
    setDynLoading,
  } = projectState;
  const { user, getUser } = useUsers();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [initValues, setInitValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [autocompleteData, setAutocompleteData] = useState({});

  const defaultCommunication = {
    id: null,
    isNew: true,
    message: '',
    userId: user.userId,
    userName: user.userName,
    createdAt: null,
  };

  const defaultTaskList = {
    id: null,
    isNew: true,
    name: '',
    duration: 1,
    waitingTime: null,
    preparedness: null,
    startDate: selectedValues.projectStartDate,
    createdBy: user.userId,
    visOrder: null,
  };

  const defaultContact = {
    id: null,
    isNew: true,
    name: '',
    role: '',
    phoneNumber: '',
    email: '',
    createdBy: user.userId,
  };

  const defaultResourceFive = {
    id: null,
    isNew: true,
    resourceId: '',
    createdBy: user.userId,
  };

  const defaultResourceFour = {
    id: null,
    isNew: true,
    resourceId: '',
    createdBy: user.userId,
  };

  const defaultResourceThree = {
    id: null,
    isNew: true,
    resourceId: '',
    createdBy: user.userId,
  };

  const defaultResourceTwo = {
    id: null,
    isNew: true,
    resourceId: '',
    createdBy: user.userId,
  };

  const defaultResourceOne = {
    id: null,
    isNew: true,
    resourceId: '',
    leader: false,
    educationDate: null,
    createdBy: user.userId,
  };

  const def = {
    projectId: null,
    projectName: '',
    companyShortName: '',
    companyId: null,
    statusId: null,
    projectStartDate: dayjs(new Date()),
    projectEndDate: dayjs(new Date()),
    projectPrepare: '',
    userId: user, //@TODO legyeb disabled szerintem? Kérdezzük meg Attilát
    projectDescription: '',
    projectGroupId: null,
    orderNumber: '',
    active: true,
    provinceId: null,
    generatedName: '',
    communication: [],
    contact: [],
    resourceFive: [],
    resourceFour: [],
    resourceThree: [],
    resourceTwo: [],
    resourceOne: [],
    taskList: [],
  };

  useEffect(() => {
    if (!Object.keys(user).length) getUser();

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          statusId: autocompleteData?.status?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          status: autocompleteData?.status?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    if (submenu) {
      initDefaultValues();
    }
  }, [submenu]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  useEffect(() => {
    setDefaultValues((prev) => {
      return { ...prev, userId: user.userId };
    });

    setDefaultSelectedValues((prev) => {
      return {
        ...prev,
        user: user,
      };
    });
  }, [user]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .statusToForm(3)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .companyToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .provinceToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceOneToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceTwoToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceThreeToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceFourToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceFiveToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .taskListTemplatesToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          status: resp[0],
          company: resp[1],
          province: resp[2],
          resourceOne: resp[3],
          resourceTwo: resp[4],
          resourceThree: resp[5],
          resourceFour: resp[6],
          resourceFive: resp[7],
          taskList: resp[8],
        };
        setAutocompleteData(aData);

        const acOptions = {
          status: generateAutocompleteItems(aData.status.statusItems, 'itemName', 'itemId'),
          company: generateAutocompleteItems(aData.company, 'companyName', 'companyId'),
          province: generateAutocompleteItems(aData.province, 'name', 'id'),
          resourceOne: generateAutocompleteItems(aData.resourceOne, 'name', 'id'),
          resourceTwo: generateAutocompleteItems(aData.resourceTwo, 'name', 'id'),
          resourceThree: generateAutocompleteItems(aData.resourceThree, 'name', 'id'),
          resourceFour: generateAutocompleteItems(aData.resourceFour, 'name', 'id'),
          resourceFive: generateAutocompleteItems(aData.resourceFive, 'machineName', 'machineId'),
          taskList: generateAutocompleteItems(aData.taskList, 'name', 'id'),
        };
        setAutoCompleteOptions(acOptions);
        setLoading(false);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  const validationSchema = Yup.object().shape({
    projectId: Yup.string().nullable(),
    projectName: Yup.string().required(),
    companyShortName: Yup.string().nullable(),
    companyId: Yup.string().nullable(),
    statusId: Yup.string().nullable(),
    projectStartDate: Yup.string().required(),
    projectEndDate: Yup.string().required(),
    projectPrepare: Yup.number().nullable().positive(),
    userId: Yup.string().required(),
    projectDescription: Yup.string().max(1000),
    projectGroupId: Yup.string().nullable(),
    orderNumber: Yup.string().nullable(),
    active: Yup.string().nullable(),
    provinceId: Yup.string().nullable(),
    communication: Yup.array().of(
      Yup.object().shape({
        message: Yup.string().required(),
      })
    ),
    contact: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(),
        role: Yup.string().required(),
        phoneNumber: Yup.string().nullable(),
        email: Yup.string().nullable(),
      })
    ),
    resourceFive: Yup.array().of(
      Yup.object().shape({
        resourceId: Yup.string().required(),
      })
    ),
    resourceFour: Yup.array().of(
      Yup.object().shape({
        resourceId: Yup.string().required(),
      })
    ),
    resourceThree: Yup.array().of(
      Yup.object().shape({
        resourceId: Yup.string().required(),
      })
    ),
    resourceTwo: Yup.array().of(
      Yup.object().shape({
        resourceId: Yup.string().required(),
      })
    ),
    resourceOne: Yup.array().of(
      Yup.object().shape({
        resourceId: Yup.string().required(),
        leader: Yup.string().nullable(),
        educationDate: Yup.string().nullable(),
      })
    ),
    taskList: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(),
        duration: Yup.number().positive().required(),
        startDate: Yup.string().required(),
        preparedness: Yup.number().positive().nullable(),
      })
    ),
  });

  const valuesToForm = () => {
    const {
      projectId,
      projectName,
      companyShortName,
      companyId,
      statusId,
      projectStartDate,
      projectEndDate,
      projectPrepare,
      userId,
      projectDescription,
      projectGroupId,
      orderNumber,
      active,
      provinceId,
      generatedName,
      projectCommunication,
      projectContact,
      projectResourceFive,
      projectResourceFour,
      projectResourceThree,
      projectResourceTwo,
      projectResourceOne,
      taskLists,
    } = selectedValues;

    setInitValues({
      projectId,
      projectName,
      companyShortName: companyShortName ?? defaultValues.companyShortName,
      companyId,
      statusId,
      projectStartDate: dayjs(projectStartDate),
      projectEndDate: dayjs(projectEndDate),
      projectPrepare: projectPrepare ?? defaultValues.projectPrepare,
      userId,
      projectDescription,
      projectGroupId,
      orderNumber: orderNumber ?? defaultValues.orderNumber,
      active,
      provinceId,
      generatedName,
      dynamicFields: getDynVals(dynProps, selectedValues.dynamicProperties),
      communication:
        projectCommunication &&
        projectCommunication.map((rc) => ({
          id: rc.id,
          message: rc.message,
          userId: rc.user.userId,
          userName: rc.user.userName,
          createdAt: rc.createdAt,
        })),
      contact:
        projectContact &&
        projectContact.map((pc) => ({
          id: pc.id,
          name: pc.name,
          role: pc.role,
          phoneNumber: pc.phoneNumber,
          email: pc.email,
        })),
      resourceFive:
        projectResourceFive &&
        projectResourceFive.map((pr) => ({
          id: pr.listId,
          resourceId: pr.resourceId,
        })),
      resourceFour:
        projectResourceFour &&
        projectResourceFour.map((pr) => ({
          id: pr.listId,
          resourceId: pr.resourceId,
        })),
      resourceThree:
        projectResourceThree &&
        projectResourceThree.map((pr) => ({
          id: pr.listId,
          resourceId: pr.resourceId,
        })),
      resourceTwo:
        projectResourceTwo &&
        projectResourceTwo.map((pr) => ({
          id: pr.listId,
          resourceId: pr.resourceId,
        })),
      resourceOne:
        projectResourceOne &&
        projectResourceOne.map((pr) => ({
          id: pr.listId,
          resourceId: pr.resourceId,
          leader: pr.leader,
          educationDate: pr.educationDate,
        })),
      taskList:
        taskLists &&
        taskLists.map((tL) => ({
          id: tL.id,
          name: tL.name,
          duration: tL.duration,
          startDate: tL.startDate,
          visOrder: tL.visOrder,
          preparedness: tL.preparedness,
        })),
    });
  };

  const handleSubmit = (values) => {
    const projectResourceOne = values.resourceOne || [];
    const projectResourceTwo = values.resourceTwo || [];

    if (params.PROJECT_WARNINGS === 'Y') {
      if (projectResourceOne.length > 0) {
        const typeFourCount = projectResourceOne.filter(
          (resource) => resource.resourceOne?.type?.itemName === '4'
        ).length;

        if (typeFourCount < 2) {
          addNotification({
            content: 'A projekt nem tartalmaz két 4-es típusú dolgozót!',
            type: NotificationType.WARNING,
          });
        }
      }

      if (projectResourceOne.length > 0 && projectResourceTwo.length === 0) {
        addNotification({
          content: 'A projekt nem tartalmaz autókat!',
          type: NotificationType.WARNING,
        });
      }
    }

    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      projectPrepare: parseInt(values.projectPrepare),
      projectStartDate: dayjs(values.projectStartDate).toISOString(),
      projectEndDate: dayjs(values.projectEndDate).toISOString(),
      updatedBy: user.userId,
    };

    projectService.updateProject(updData, selectedValues.projectId).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);

      if (dialog) {
        dialog.setTaskChanged(true);
        dialog.handleClose();
      }
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      projectPrepare: parseInt(values.projectPrepare),
      projectStartDate: dayjs(values.projectStartDate).toISOString(),
      projectEndDate: dayjs(values.projectEndDate).toISOString(),
      createdBy: user.userId,
    };

    projectService
      .createProject(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });

        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setSelectedValues(data);

        setRowSelectionModel(data.projectId);

        if (dialog) {
          dialog.setTaskChanged(true);
          dialog.handleClose();
        }
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    projectService.deleteProject(deleteData, selectedValues.projectId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultValues);
    });
  };

  const onAddClick = () => {
    setSelectedValues(defaultSelectedValues);
    setViewField(false);
    setAddField(false);
  };

  const onModifyClick = () => {
    setViewField(false);
    setEditButtonvisible(false);
  };

  const onDeleteClick = () => {
    setShowDeleteDialog(true);
  };

  const onCopyClick = () => {
    setViewField(false);
    setAddField(false);
  };

  return (
    <>
      <div className="h-full overflow-auto custom-scrollbar">
        <div className="flex justify-between items-center">
          <FormHeaderUI
            addClick={!infoPanel && !dialog ? onAddClick : null}
            addDisabled={!viewField || !canEdit}
            modClick={!infoPanel ? onModifyClick : null}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={
              (typeof params.PROJECT_DELETE_BUTTON === 'undefined' || params.PROJECT_DELETE_BUTTON === 'Y') &&
              !infoPanel
                ? onDeleteClick
                : null
            }
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={!infoPanel ? onCopyClick : null}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />
          {/*<History disabled={operatorsDisabled} onClick={() => setHistoryDialogOpen(!historyDialogOpen)} />*/}
        </div>

        <Formik
          initialValues={initValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
          enableReinitialize
          //validateOnMount={false}
        >
          {({ values, setFieldValue, errors, validateField, resetForm }) => (
            <Form>
              <ProjectFields
                viewField={viewField}
                selectedValues={selectedValues}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                validateField={validateField}
                params={params}
                autoCompleteOptions={autoCompleteOptions}
              />

              <ProjectTabs
                disabled={viewField}
                submenuId={submenu}
                addField={addField}
                setFieldValue={setFieldValue}
                validateField={validateField}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                setRefetchTable={setRefetchTable}
                user={user}
                values={values}
                errors={errors}
                submenuNames={submenuNames}
                params={params}
                canEdit={canEdit}
                setDefaultValues={setDefaultValues}
                dynProperties={{ dynProps, setDynProps }}
                infoPanel={infoPanel}
                defaultField={defaultCommunication}
                defaultContact={defaultContact}
                defaultResourceFive={defaultResourceFive}
                defaultResourceFour={defaultResourceFour}
                defaultResourceThree={defaultResourceThree}
                defaultResourceTwo={defaultResourceTwo}
                defaultResourceOne={defaultResourceOne}
                autoCompleteOptions={autoCompleteOptions}
                checkLists={selectedValues?.checkLists ?? []}
                defaultTaskList={defaultTaskList}
                lineId={selectedValues.projectId}
                dynLoadingState={{ dynLoading, setDynLoading }}
                autocompleteData={autocompleteData}
              />

              <FormButtonPanelUI
                onCancelClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                  setAddField(true);
                  resetForm();
                }}
                disabled={editButtonVisible && addField}
              />
            </Form>
          )}
        </Formik>
      </div>
      <HistoryDialog
        open={historyDialogOpen}
        handleClose={() => {
          setHistoryDialogOpen(false);
        }}
        formId={submenu}
        lineId={selectedValues.projectId}
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
