import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useLoader } from '../../provider/LoaderProvider';

const LoaderUI = () => {
  const { loading } = useLoader();

  return (
    <>
      {loading && (
        <div
          className="absolute top-0 left-0 w-full h-full flex justify-center items-center "
          style={{ zIndex: 10000 }}
        >
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 "></div>
          <div className="relative">
            <CircularProgress size={70} />
          </div>
        </div>
      )}
    </>
  );
};

export default LoaderUI;
