import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { FormGroup, FormHelperText } from '@mui/material';

const CheckboxGroupSingleSelectUI = ({ label, option, value, onChange, helperText, disabled, ...props }) => {
  const handleCheckboxChange = (event) => {
    const { checked, name } = event.target;
    const newValue = checked ? name : null;

    onChange(newValue);
  };

  return (
    <>
      <FormControl component="fieldset" disabled={disabled} sx={{ marginTop: 1 }}>
        {label && <FormLabel component="legend">{label}</FormLabel>}
        <FormGroup {...props}>
          {option.map((item, index) => (
            <FormControlLabel
              key={index}
              control={<Checkbox name={item.value} checked={value === item.value} onChange={handleCheckboxChange} />}
              label={item.label}
            />
          ))}
        </FormGroup>
      </FormControl>
      <FormHelperText>{helperText}</FormHelperText>
    </>
  );
};

export default CheckboxGroupSingleSelectUI;
