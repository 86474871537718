import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { isEqual } from 'lodash';
import Yup from '../../utils/yup';

import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import useMenus from '../../context/MenuContext';
import useUsers from '../../context/UsersContext';
import { FormButtonPanelUI, FormHeaderUI, FormBase } from '../Interface';
import currencyService from '../../service/currency.service';
import CurrencyFields from './CurrencyFields';
import CurrencyReports from './CurrencyReports';

export const CurrencyPage = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const [refetchTable, setRefetchTable] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [canEdit, setCanEdit] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const def = {
    code: '',
    isoCode: '',
    name: '',
    priceRounding: '',
    amountRounding: '',
    amountDifference: '',
    active: true,
  };

  useEffect(() => {
    getMenus();
    if (!Object.keys(user).length) getUser();

    setDefaultValues(def);
    setDefaultSelectedValues(def);
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('currency');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const validationSchema = Yup.object().shape({
    code: Yup.string().required(),
    isoCode: Yup.string().required(),
    name: Yup.string().required(),
    priceRounding: Yup.number().required(),
    amountRounding: Yup.number().required(),
    amountDifference: Yup.number().required(),
    active: Yup.boolean(),
  });

  const valuesToForm = () => {
    const { code, isoCode, name, priceRounding, amountRounding, amountDifference, active } = selectedValues;

    setInitValues({
      code,
      isoCode,
      name,
      priceRounding,
      amountRounding,
      amountDifference,
      active,
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    currencyService.updateItem(updData).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    currencyService
      .createItem(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });

        setSelectedValues(data);
        setRowSelectionModel(data.itemCode);
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  return (
    <FormBase
      name="Currency"
      leftPanelContent={
        <>
          <div className="h-full overflow-auto custom-scrollbar">
            <FormHeaderUI
              addClick={() => {
                setSelectedValues(defaultSelectedValues);
                setViewField(false);
                setAddField(false);
              }}
              addDisabled={!viewField || !canEdit}
              modClick={() => {
                setViewField(false);
                setEditButtonvisible(false);
              }}
              modDisabled={!viewField || operatorsDisabled || !canEdit}
              copyClick={() => {
                setViewField(false);
                setAddField(false);
              }}
              copyDisabled={!viewField || operatorsDisabled || !canEdit}
            />

            <Formik
              initialValues={initValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ resetForm }) => (
                <Form>
                  <CurrencyFields viewField={viewField} selectedValues={selectedValues} addField={addField} />

                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);

                      resetForm();
                    }}
                    disabled={editButtonVisible && addField}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </>
      }
      rightPanelContent={
        <CurrencyReports
          refetch={{ refetchTable, setRefetchTable }}
          values={{ selectedValues, setSelectedValues }}
          viewField={viewField}
          operatorsDisabled={operatorsDisabled}
          rowSelection={{ rowSelectionModel, setRowSelectionModel }}
        />
      }
    />
  );
};

export default CurrencyPage;
