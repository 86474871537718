import moment from 'moment';
import dayjs from 'dayjs';

import { DependencyTypes } from '../../config/DependencyTypes';

export const collectRelatedRecords = (structure, filterId) => {
  const initialRecord = structure?.find((item) => item.id === filterId);
  if (!initialRecord) {
    return [];
  }
  const relatedRecords = new Set();
  const queue = [initialRecord];

  while (queue.length) {
    const currentItem = queue.shift();
    if (!currentItem || relatedRecords.has(currentItem.id)) continue;

    relatedRecords.add(currentItem.id);

    // Add parent records
    structure.forEach((item) => {
      item.dependencies.forEach((dep) => {
        currentItem.dependencies.forEach((d) => {
          if (d.parentId === dep.id) {
            if (!relatedRecords.has(item.id)) {
              queue.push(item);
            }
          }
        });
      });
    });

    // Add child records
    structure.forEach((item) => {
      item.dependencies.forEach((dep) => {
        currentItem.dependencies.forEach((d) => {
          if (dep.parentId === d.id) {
            if (!relatedRecords.has(item.id)) {
              queue.push(item);
            }
          }
        });
      });
    });
  }

  return Array.from(relatedRecords);
};

export const collectRelatedRecordsData = (structure, filterId) => {
  const initialRecord = structure.find((item) => item.id === filterId);
  if (!initialRecord) {
    return [];
  }
  const relatedRecords = new Set();
  const relatedRecordsData = [];
  const queue = [initialRecord];

  while (queue.length) {
    const currentItem = queue.shift();
    if (!currentItem || relatedRecords.has(currentItem.id)) continue;

    relatedRecords.add(currentItem.id);

    // Add parent records
    structure.forEach((item) => {
      item.dependencies.forEach((dep) => {
        currentItem.dependencies.forEach((d) => {
          if (d.parentId === dep.id) {
            if (!relatedRecords.has(item.id)) {
              //relatedRecordsData.find((rd)=>{return rd.})
              relatedRecordsData.push({ ...item, isParent: true });
              queue.push(item);
            }
          }
        });
      });
    });

    // Add child records
    structure.forEach((item) => {
      item.dependencies.forEach((dep) => {
        currentItem.dependencies.forEach((d) => {
          if (dep.parentId === d.id) {
            if (!relatedRecords.has(item.id)) {
              //dep.parentid ha nincs még benne a relatedrecords.dependencies.parentid
              const notRelevant = relatedRecordsData.find((record) => {
                return record.dependencies.find((dependency) => {
                  return dependency.id === dep.parentId && record.isParent;
                });
              });
              if (!notRelevant) {
                relatedRecordsData.push({ ...item, isParent: false });
                queue.push(item);
              }
            }
          }
        });
      });
    });
  }
  return relatedRecordsData;
};

export const validateDependencies = (changes, currentTasks, prodorderData) => {
  for (const { task } of changes) {
    const currentTask = currentTasks.find((t) => t.id === task.id);
    const additQty = parseInt(prodorderData.eventData.AdditQty);
    const parentAddit = prodorderData.parent ? parseInt(prodorderData.parent.eventData.AdditQty) : 0;
    if (currentTask) {
      const childTasks = currentTasks.filter((t) =>
        t.dependenciesWithType.some((dep) => dep.parentId === Number(currentTask.id))
      );

      const parentTasks = currentTasks.filter((t) =>
        currentTask.dependenciesWithType.some((dep) => dep.parentId === Number(t.id))
      );

      console.log({ task, currentTask, parentTasks, childTasks });

      // TODO Release left mouse click outside of the gantt problem
      /*if (additQty > 0) {
        //validate min latency
        const delayedStart = dayjs(currentTask._end).add(additQty, 'd');
        console.log(delayedStart.toDate());
        for (const task of childTasks) {
          const isAfter = dayjs(task._start).isAfter(delayedStart);

          if (!isAfter) {
            alert(`Nincs betartva a várakozási idő! ${prodorderData.eventData.ItemCode} - ${additQty} nap`);
            return false;
          }
        }
      }*/

      if (parentAddit > 0) {
        const delayedStart = dayjs(parentTasks[0]._end).add(parentAddit, 'd');

        const isAfter = dayjs(currentTask._start).isAfter(delayedStart);

        if (!isAfter) {
          alert(`Nincs betartva a várakozási idő! ${prodorderData.parent.eventData.ItemCode} - ${parentAddit} nap`);
          return false;
        }
      }

      for (let i = 0; i < currentTask.dependenciesWithType.length; i++) {
        if (currentTask.dependenciesWithType[i].type === DependencyTypes.FS) {
          for (const childTask of childTasks) {
            if (moment(currentTask._end).isAfter(moment(childTask._start))) {
              alert(
                `FS típusú validáció megsértése: Szülő feladat "${currentTask.name}" befejezés dátuma ${moment(currentTask._end)} nem lehet később mint a gyermek feladat "${childTask.name}" kezdési dátuma ${moment(childTask._start)}.`
              );
              return false;
            }
          }

          for (const parentTask of parentTasks) {
            if (moment(parentTask._end).isAfter(moment(currentTask._start))) {
              alert(
                `FS típusú validáció megsértése: Gyermek feladat "${currentTask.name}" kezdési dátuma ${moment(currentTask._start)} nem lehet később mint a szülő feladatának "${parentTask.name}" befejezés dátuma ${moment(parentTask._end)}.`
              );
              return false;
            }
          }
        }
        if (currentTask.dependenciesWithType[i].type === DependencyTypes.SS) {
          for (const childTask of childTasks) {
            if (moment(currentTask._start).isBefore(moment(childTask._start))) {
              alert(
                `SS típusú validáció megsértése: Szülő feladat "${currentTask.name}" kezdési dátuma ${moment(currentTask._start)} nem lehet előbb mint a gyermek feladat "${childTask.name}" kezdési dátuma ${moment(childTask._start)}.`
              );
              return false;
            }
          }

          for (const parentTask of parentTasks) {
            if (moment(parentTask._start).isBefore(moment(currentTask._start))) {
              alert(
                `SS típusú validáció megsértése: Gyermek feladat "${currentTask.name}" kezdési dátuma ${moment(currentTask._start)} nem lehet előbb mint a szülő feladatának "${parentTask.name}" kezdési dátuma ${moment(parentTask._start)}.`
              );
              return false;
            }
          }
        }
        if (currentTask.dependenciesWithType[i].type === DependencyTypes.FF) {
          for (const childTask of childTasks) {
            if (moment(currentTask._end).isAfter(moment(childTask._end))) {
              alert(
                `FF típusú validáció megsértése: Szülő feladat "${currentTask.name}" befejezés dátuma ${moment(currentTask._end)} nem lehet később mint a gyermek feladat "${childTask.name}" befejezés dátuma ${moment(childTask._end)}.`
              );
              return false;
            }
          }

          for (const parentTask of parentTasks) {
            if (moment(parentTask._end).isAfter(moment(currentTask._end))) {
              alert(
                `FF típusú validáció megsértése: Gyermek feladat "${currentTask.name}" befejezés dátuma ${moment(currentTask._end)} nem lehet később mint a szülő feladatának "${parentTask.name}" befejezés dátuma ${moment(parentTask._end)}.`
              );
              return false;
            }
          }
        }
        if (currentTask.dependenciesWithType[i].type === DependencyTypes.SF) {
          for (const childTask of childTasks) {
            if (moment(currentTask._start).isAfter(moment(childTask._end))) {
              alert(
                `SF típusú validáció megsértése: Szülő feladat "${currentTask.name}" kezdési dátuma ${moment(currentTask._start)} nem lehet később mint a gyermek feladat "${childTask.name}" befejezés dátuma ${moment(childTask._end)}.`
              );
              return false;
            }
          }

          for (const parentTask of parentTasks) {
            if (moment(parentTask._start).isAfter(moment(currentTask._end))) {
              alert(
                `SF típusú validáció megsértése: Gyermek feladat "${currentTask.name}" befejezés dátuma ${moment(currentTask._end)} nem lehet később mint a szülő feladatának "${parentTask.name}" kezdési dátuma ${moment(parentTask._start)}.`
              );
              return false;
            }
          }
        }
      }
    }
  }
  return true;
};

export const mapDependencies = (structure) => {
  return structure.map((item) => {
    const dependencies = item.dependencies.filter((dep) => dep.parentId !== null).map((dep) => dep.parentId);
    const dependencyTypes = item.dependencies.map((dep) => dep.type);

    let taskId;
    let isParent = false;
    if (item.dependencies.length === 0) {
      taskId = item.id;
      isParent = true;
    } else if (item.dependencies.length === 1) {
      taskId = item.dependencies[0].id;
    } else if (item.dependencies.some((dep) => dep.parentId === null)) {
      taskId = item.dependencies.find((dep) => dep.parentId === null).id;
    } else {
      taskId = item.dependencies[0].id;
    }

    return {
      id: taskId.toString(),
      itemId: item.id,
      name: `${item.eventData.ItemCode} ${dependencyTypes.join(', ')}`,
      start: item.start ? moment(item.start).format('YYYY-MM-DD') : null,
      end: item.end ? moment(item.end).format('YYYY-MM-DD') : null,
      resourceId: item.resourceId,
      progress: item.progress || 0,
      dependencies: dependencies.join(', '),
      dependenciesWithType: item.dependencies,
      custom_class: dependencyTypes.join(', '),
      _end: new Date(item.end),
      _start: new Date(item.start),
    };
  });
};
