import React, { useEffect, useRef } from 'react';
import { Tooltip } from '@mui/material';
import TooltipContent from './TooltipContent';
import ChipContent from './ChipContent';

const EventChip = ({ eventInfo, defaultStyles }) => {
  const eventElementRef = useRef(null);

  useEffect(() => {
    const eventElement = eventElementRef.current?.closest('.fc-h-event');
    if (eventElement) {
      if (eventInfo.isResizing || eventInfo.isDragging) {
        eventElement.style.backgroundColor = 'rgba(254, 215, 170, 0.6)';
      }
    }
  }, [eventInfo.isResizing, eventInfo.isDragging]);

  const handleMouseEnter = (e) => {
    const eventElement = e.currentTarget.closest('.fc-h-event');
    if (!eventInfo.isResizing && !eventInfo.isDragging) {
      eventElement.style.backgroundColor = '#55DD62';
    }
  };

  const handleMouseLeave = (e) => {
    const eventElement = e.currentTarget.closest('.fc-h-event');
    if (!eventInfo.isResizing && !eventInfo.isDragging) {
      eventElement.style.backgroundColor = defaultStyles.backgroundColor;
    }
  };

  return (
    <Tooltip
      arrow
      placement="top"
      title={<TooltipContent eventInfo={eventInfo} />}
      slotProps={{
        tooltip: {
          sx: {
            color: '#514E6A',
            backgroundColor: '#ffff',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            width: '200px',
          },
        },
        arrow: {
          sx: {
            color: '#ffff',
          },
        },
      }}
    >
      <div
        ref={eventElementRef}
        className="font-bold truncate  h-[20px]"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <ChipContent eventInfo={eventInfo} />
      </div>
    </Tooltip>
  );
};

export default EventChip;
