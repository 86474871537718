import React from 'react';

import { FormBase } from '../../Interface';
import ResourceOneReports from './ResourceOneReports';
import { ResourceOnePage } from './ResourceOnePage';
import { useResourceOne } from '../../../hooks/useResourceOne';

export const ResourceOneMain = () => {
  const resourceOneState = useResourceOne();

  return (
    <FormBase
      name="ResourceOne"
      leftPanelContent={<ResourceOnePage resourceOneState={resourceOneState} />}
      rightPanelContent={
        <> {!resourceOneState.isLoading && <ResourceOneReports resourceOneState={resourceOneState} />}</>
      }
    />
  );
};
