import { useState, useEffect, useMemo } from 'react';

import { dataGridPremiumColumns } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import planAbsenceService from '../../service/planAbsence.service';

const PlanAbsenceReports = ({ refetch, values, viewField, operatorsDisabled, rowSelection, autocompleteOptions }) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'startDate', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    planAbsenceService.getPlanAbsences(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'startDate',
        headerName: 'Kezdő dátum',
        type: 'date',
        valueGetter: (_data, row) => {
          return new Date(row.startDate);
        },
      },
      {
        field: 'endDate',
        headerName: 'Befejező dátum',
        type: 'date',
        valueGetter: (_data, row) => {
          return new Date(row.endDate);
        },
      },
      {
        field: 'resourceOneId',
        headerName: 'Dolgozó',
        type: 'singleSelect',
        renderCell: (params) => {
          return params.row.resourceOne?.name;
        },
        valueOptions: autocompleteOptions.resourceOne,
      },
      {
        field: 'typeId',
        headerName: 'Típus',
        type: 'singleSelect',
        renderCell: (params) => {
          return params.row.type?.itemName;
        },
        valueOptions: autocompleteOptions.type,
      },
    ]);
  }, [autocompleteOptions]);

  return (
    <>
      {Object.keys(autocompleteOptions).length > 0 && (
        <DatagridPremiumUi
          datagridIdentifier={DataGridIdentifiers.planAbsenceReports}
          datagridInitialState={initialState}
          dataLoader={getList}
          rows={reports.rows}
          refetch={{ refetchTable, setRefetchTable }}
          rowCount={reports.rowCount}
          columns={[...columns]}
          onRowClick={(rowData) => {
            if (!viewField) return;
            setSelectedValues(rowData.row);
          }}
          paginationAndfilterMode="server"
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          disableRowSelectionOnClick={!viewField}
        />
      )}
    </>
  );
};

export default PlanAbsenceReports;
