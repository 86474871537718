import React, { useEffect, useState } from 'react';
import { DialogUI } from '../../Interface';
import resourceOneService from '../../../service/resources/resourceOne.service';

const InfoDialog = ({ open, handleClose, selectedValues }) => {
  const [absences, setAbsences] = useState([]);

  useEffect(() => {
    if (open) {
      getCountedAbsences();
    }
  }, [open]);

  const getCountedAbsences = () => {
    resourceOneService.getAbsenceForResourceOne(selectedValues.id).then((data) => {
      setAbsences(data);
    });
  };

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent="Információs panel"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1100px',
          },
        },
      }}
    >
      <div className="max-h-[750px] overflow-y-scroll overflow-x-hidden custom-scrollbar mt-5">
        <table className="border w-full">
          <thead className="bg-tableHeaderColor">
            <tr className="grid grid-cols-2">
              <th className="border p-3 col-span-1">Tény szabadságok</th>
              <th className="border p-3 col-span-1">Tervezett szabadságok</th>
            </tr>
          </thead>
          <tbody>
            <tr className="grid grid-cols-2">
              <td className="border py-2 col-span-1 text-center">{absences.factAbsencesTotal}</td>
              <td className="border py-2 col-span-1 text-center">{absences.planAbsencesTotal}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </DialogUI>
  );
};

export default InfoDialog;
