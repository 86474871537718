import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';

import Yup from '../../utils/yup';
import dayjs, { utc } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import { DialogUI, DatePickerUI, ButtonUI, AutoCompleteSelectUI, FormLabelUI, FormErrorUI } from '../Interface';
import sapShiftLeaderReportService from '../../service/sapShiftLeaderReport.service';
import { useLoader } from '../../provider/LoaderProvider';

const AttendanceDialog = ({ show, onHide, autocompleteOptions }) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { showLoader, hideLoader } = useLoader();
  const [attendanceReportData, setAttendanceReportData] = useState({});

  const defaultValues = {
    workerId: null,
    startDate: dayjs().startOf('month'),
    endDate: dayjs().endOf('month'),
  };

  useEffect(() => {
    setAttendanceReportData(defaultValues);
  }, []);

  const validationSchema = Yup.object().shape({
    workerId: Yup.string().required(),
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
  });

  const handleSubmit = (values) => {
    showLoader();
    const val = {
      ...values,
      startDate: dayjs(values.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(values.endDate).format('YYYY-MM-DD'),
    };
    sapShiftLeaderReportService.getPdf(val).finally(() => hideLoader());
  };

  return (
    <DialogUI open={show} headerContent="Jelenlét" onClose={onHide}>
      <div className=" text-center">
        <div className="flex mt-2 gap-10 items-center justify-center z-50">
          <Formik
            initialValues={attendanceReportData}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, setFieldValue, validateField, values }) => (
              <Form>
                <div className="col-span-2 ">
                  <Field
                    type="autocomplete"
                    name="workerId"
                    component={AutoCompleteSelectUI}
                    fullWidth
                    label={<FormLabelUI text="Dolgozó" />}
                    variant="standard"
                    helperText={<FormErrorUI message={errors.workerId} />}
                    onChange={(_e, newVal) => {
                      setFieldValue('workerId', newVal?.value ?? null).then(() => {
                        validateField('workerId');
                      });
                    }}
                    InputLabelProps={{ shrink: values.workerId !== '' }}
                    selectedValue={values.workerId}
                    //selectedLabelValue={selectedValues?.worker?.FullName}
                    dataset={autocompleteOptions?.employee}
                  />
                </div>
                <div className="col-span-2 ">
                  <Field
                    type="date"
                    name="startDate"
                    component={DatePickerUI}
                    value={values.startDate}
                    fullWidth
                    label={<FormLabelUI text="Kezdő dátum" />}
                    variant="standard"
                    helperText={<FormErrorUI message={errors.startDate} />}
                    onChange={(date) => {
                      setFieldValue('startDate', date).then(() => {
                        validateField('startDate');
                      });
                    }}
                    InputLabelProps={{ shrink: values.startDate !== '' }}
                  />
                </div>
                <div className="col-span-2 ">
                  <Field
                    type="date"
                    name="endDate"
                    component={DatePickerUI}
                    value={values.endDate}
                    fullWidth
                    label={<FormLabelUI text="Vége dátum" />}
                    variant="standard"
                    helperText={<FormErrorUI message={errors.endDate} />}
                    onChange={(date) => {
                      setFieldValue('endDate', date).then(() => {
                        validateField('endDate');
                      });
                    }}
                    InputLabelProps={{ shrink: values.endDate !== '' }}
                  />
                </div>

                <div className="px-4 mt-8 flex justify-center gap-3">
                  <ButtonUI text="Riport készítése" className="bg-success" type="submit"></ButtonUI>
                  <ButtonUI
                    text="Mégse"
                    className=""
                    color="blue-gray"
                    type="button"
                    variant="gradient"
                    onClick={onHide}
                  ></ButtonUI>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </DialogUI>
  );
};

export default AttendanceDialog;
