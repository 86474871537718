import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography, Box } from '@mui/material';

const CircularStaticProgressUI = (props) => {
  const { circleColor, value } = props;
  return (
    <Box sx={{ position: 'relative' }}>
      {/* Haladási sáv progress, ami mindig 100% */}
      <CircularProgress
        variant="determinate"
        sx={{ color: '#E9EAEB !important', position: 'absolute' }}
        {...props}
        value={100}
      />
      {/* Előtér progress bar, ami a tényleges haladást mutatja a value alapján */}
      <CircularProgress
        {...props}
        variant="determinate"
        sx={{
          color: `${circleColor ?? 'blue'} !important`,
        }}
      />
      <Box
        sx={{
          top: '-5px',
          left: 0,
          bottom: 0,
          right: '1px',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="grey">
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularStaticProgressUI;
