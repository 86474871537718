import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';

const TooltipContent = ({ eventInfo }) => {
  const task = eventInfo.event._def.extendedProps.eventData;

  return (
    <div>
      <div className="flex justify-between">
        <Typography>Kezdés:</Typography>
        <Typography>{dayjs(task?.startDate).format('YYYY.MM.DD')}</Typography>
      </div>
      <div className="flex justify-between">
        <Typography>Befejezés:</Typography>
        <Typography>{dayjs(task?.startDate).add(parseInt(task?.duration), 'day').format('YYYY.MM.DD')}</Typography>
      </div>
      <div className="flex justify-between">
        <Typography>Időintervallum:</Typography>
        <Typography>{task?.duration} nap</Typography>
      </div>
      <div className="flex justify-between">
        <Typography>Készültség:</Typography>
        <Typography>{task?.preparedness} %</Typography>
      </div>
    </div>
  );
};

export default TooltipContent;
