import React, { useState } from 'react';
import { Button } from '@material-tailwind/react';
import { Details, DependencyList, DependenciesGantt } from './SapProductOrderDialogTabs';
import TabsUI from '../Interface/TabsUI';
import { DialogUI } from '../Interface';

const SapProductOrderDialog = ({
  open,
  handleClose,
  task,
  getProductionOrderById,
  productionOrdersWithSapProDependencies,
  updateBatch,
  getProductionOrdersWithSapProDependencies,
  getProductionOrders,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleCloseWithResetTab = () => {
    setActiveTab(0);
    handleClose();
  };

  const SapProductOrderDialogTabs = [
    {
      name: 'Tulajdonságok',
      content: (
        <Details
          task={task}
          getProductionOrders={getProductionOrders}
          productionOrdersWithSapProDependencies={productionOrdersWithSapProDependencies}
          updateBatch={updateBatch}
        />
      ),
    },
    {
      name: 'Függőségek',
      content: (
        <DependencyList
          task={task}
          getProductionOrderById={getProductionOrderById}
          getProductionOrdersWithSapProDependencies={getProductionOrdersWithSapProDependencies}
        />
      ),
    },
    {
      name: 'Gantt',
      content: (
        <DependenciesGantt
          task={task}
          productionOrdersWithSapProDependencies={productionOrdersWithSapProDependencies}
          updateBatch={updateBatch}
        />
      ),
    },
  ];

  return (
    <DialogUI
      open={open}
      headerContent={`${task.Project} - ${task.ItemCode} - ${task.CardName}`}
      //fullWidth={true}
      maxWidth={'xl'}
      onClose={handleCloseWithResetTab}
    >
      <TabsUI activeTab={activeTab} handleTabClick={handleTabClick} disablePadding={true}>
        {SapProductOrderDialogTabs.map((tab, index) => (
          <div key={index} name={tab.name}>
            {tab.content}
          </div>
        ))}
      </TabsUI>
      <div className="px-4 mt-8 flex justify-center gap-3">
        <Button variant="gradient" color="blue-gray" onClick={handleCloseWithResetTab}>
          <span>Bezárás</span>
        </Button>
      </div>
    </DialogUI>
  );
};

export default SapProductOrderDialog;
