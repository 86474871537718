import { useEffect, useState } from 'react';

import { GridRowModes, GridToolbarContainer, GridRowEditStopReasons } from '@mui/x-data-grid';
import { Field, Form, Formik } from 'formik';
import dayjs from 'dayjs';

import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import { dataGridColumns } from '../../utils/helper';
import Yup from '../../utils/yup';
import propertyService from '../../service/property.service';

import { DataGridUI, DataGridIdentifiers, ButtonUI, FormErrorUI, InputUI, ActionButtonUI } from '../Interface';

function EditToolbar(props) {
  const { setRows, setRowModesModel, rows, rowModesModel, setCurrentRow, id, disabled } = props;

  const handleClick = () => {
    const newRow = {
      id: Date.now(),
      name: '',
      color: '',
      isNew: true,
    };

    setRows((oldRows) => [newRow, ...oldRows]);
    setCurrentRow(newRow);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newRow.id]: { mode: GridRowModes.Edit, fieldToFocus: 'id' },
    }));
  };

  const isNewRow = rows.some(
    (row) => row.isNew || Object.values(rowModesModel).some((row) => row.mode === GridRowModes.Edit)
  );

  return (
    <GridToolbarContainer className="bg-headerToolbar">
      <ButtonUI
        className="bg-success mb-1 relative z-50"
        text="Elem hozzáadása"
        disabled={isNewRow || !id || disabled}
        onClick={handleClick}
      />
    </GridToolbarContainer>
  );
}

const SelectListDatagrid = ({ property, id, setRefetchTable, user, disabled, selectedValues, setSelectedValues }) => {
  const { setPropertyOption, propertyOption } = property;
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [currentRow, setCurrentRow] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (selectedValues?.defaultValue !== null) {
      const defVal = [selectedValues.defaultValue];
      setSelectedRows(defVal);
    } else {
      setSelectedRows([]);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (propertyOption.length > 0) {
      setRows(propertyOption);
    }
  }, [propertyOption]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    color: Yup.string()
      .nullable()
      .matches(/^$|^#(?:[0-9a-fA-F]{3}){1,2}$/)
      .max(7),
  });

  const handleAddRow = (updatedRow) => {
    const insertObject = {
      ...updatedRow,
      createdBy: user?.userId,
    };
    propertyService.addPropertyOptionToList(insertObject, id).then((data) => {
      addNotification({
        content: 'Sikeres hozzáadás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setPropertyOption((prev) => {
        return [...prev, data];
      });
    });
  };

  const handleUpdateRow = (updatedRow, id) => {
    const updData = {
      ...updatedRow,
      updatedBy: user?.userId,
    };
    propertyService.updatePropertyOption(updData, id).then(() => {
      addNotification({
        content: 'Sikeres mentés!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
    });
  };

  const handleDeleteRow = (id) => {
    const deleteData = {
      deletedBy: user.userId,
      deletedAt: dayjs(new Date()),
    };
    propertyService.deletePropertyOptionFromList(deleteData, id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setPropertyOption((prevOption) => prevOption.filter((option) => option.id !== id));
      setRefetchTable(true);
    });
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    const rowToEdit = rows.find((row) => row.id === id);
    setCurrentRow(rowToEdit);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    handleDeleteRow(id);
  };

  const handleCancelClick = (id) => () => {
    const editedRow = rows.find((row) => row.id === id);

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    } else {
      setRows((prevRows) => prevRows.map((row) => (row.id === id ? { ...editedRow, isNew: false } : row)));
    }
    setCurrentRow(null);
  };

  const addDefaultValue = async (propertyId) => {
    if (Object.keys(selectedValues).length > 0) {
      propertyService.updateDefaultValue(id, propertyId).then((data) => {
        addNotification({
          content: 'Alapértelmezett érték frissítve!',
          type: NotificationType.SUCCESS,
        });
        setSelectedValues((prevValues) => ({
          ...prevValues,
          ...data,
        }));
      });
    }
  };

  const processRowUpdate = async (newRow) => {
    await validationSchema.validate(newRow, { abortEarly: false });
    const updatedRow = { ...newRow, isNew: newRow.isNew ?? false };
    const updatedRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row));

    updatedRows.sort((a, b) => a.id - b.id);

    setRows(updatedRows);

    if (updatedRow.isNew) {
      handleAddRow(updatedRow);
    } else {
      handleUpdateRow(updatedRow, newRow.id);
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors) => {
    return dataGridColumns([
      {
        field: 'name',
        headerName: 'Választólista elemek',
        flex: 1,
        editable: true,
        renderEditCell: (params) => (
          <Field
            name="name"
            as={InputUI}
            helperText={<FormErrorUI message={errors.name} />}
            variant="standard"
            onChange={(e) => {
              setFieldValue('name', e.target.value ?? '').then(() => {
                validateField('name');
              });
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              });
            }}
          />
        ),
      },
      {
        field: 'color',
        headerName: 'Szín',
        flex: 0.5,
        editable: true,
        renderCell: (params) => {
          return params.row.color ? (
            <div
              className="rounded-full shadow-[rgba(0,_0,_0,_0.54)_0px_1px_8px] w-[20px] h-[20px] hover:cursor-pointer"
              style={{ background: params.row.color }}
            />
          ) : (
            ''
          );
        },
        renderEditCell: (params) => (
          <Field
            name="color"
            as={InputUI}
            helperText={<FormErrorUI message={errors.color} />}
            variant="standard"
            onChange={(e) => {
              setFieldValue('color', e.target.value ?? '').then(() => {
                validateField('color');
              });
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value,
              });
            }}
          />
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        flex: 0.5,
        cellClassName: 'actions',
        getActions: ({ id }) => [
          <ActionButtonUI
            id={id}
            rowModesModel={rowModesModel}
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            disabled={disabled}
          />,
        ],
      },
    ]);
  };

  return (
    <div className="sm:px-8 py-2">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <Formik
          initialValues={currentRow}
          validationSchema={validationSchema}
          onSubmit={processRowUpdate}
          validateOnMount
          enableReinitialize
        >
          {({ errors, setFieldValue, validateField }) => (
            <Form className="w-full">
              <DataGridUI
                datagridIdentifier={DataGridIdentifiers.SelectListDatagrid}
                sx={{
                  height: 608,
                  width: '100%',
                  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-columnHeaderDraggableContainer': {
                    backgroundColor: '#CEDFE8',
                  },
                }}
                columns={columns(setFieldValue, validateField, errors)}
                rows={rows}
                getRowId={(row) => row.id}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                checkboxSelection
                isRowSelectable={() => false}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  if (newRowSelectionModel.length > 0) {
                    setSelectedRows([newRowSelectionModel[newRowSelectionModel.length - 1]]);
                    addDefaultValue(newRowSelectionModel[newRowSelectionModel.length - 1]);
                  } else {
                    setSelectedRows([]);
                    addDefaultValue(null);
                  }
                }}
                selectedRows={selectedRows}
                rowSelectionModel={selectedRows}
                processRowUpdate={processRowUpdate}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    setRows,
                    setRowModesModel,
                    rows,
                    rowModesModel,
                    user,
                    setCurrentRow,
                    id,
                    disabled,
                  },
                }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SelectListDatagrid;
