import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'type';

const TypeService = {
  getType: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/types`,
      data,
    });
  },

  createType: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateType: (data, typeId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${typeId}`,
      data,
    });
  },

  createTypeItem: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/typeItem`,
      data,
    });
  },

  deleteTypeItem: (typeItemId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/typeItem/${typeItemId}`,
    });
  },

  updateTypeItem: (data, typeId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/typeItem/${typeId}`,
      data,
    });
  },

  updateDefaultValue: (id, itemId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/defaultValue/${id}/${itemId}`,
    });
  },

  orderTypeItem: (itemId, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/orderTypeItem/${itemId}`,
      data,
    });
  },
};

export default TypeService;
