import React, { useEffect, useState } from 'react';
import { AutoCompleteSelectUI, ButtonUI, DialogUI, FormLabelUI } from '../../../Interface';
import TemplateItemTable from './TemplateItemTable';

const TemplateImportDialog = ({ open, handleClose, onItemAdd, autoCompleteOptions, autocompleteData }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (selectedTemplate) {
      const selected = autocompleteData.taskList.find((template) => template.id === selectedTemplate);
      setItems(selected ? selected.taskListTemplateItem : []);
    } else {
      setItems([]);
    }
  }, [selectedTemplate]);

  const handleAddTemplate = () => {
    const template = autocompleteData.taskList.find((template) => template.id === selectedTemplate);
    onItemAdd(template.taskListTemplateItem);
    onClose();
  };

  const onClose = () => {
    handleClose();
    setSelectedTemplate(null);
  };

  return (
    <DialogUI
      open={open}
      onClose={onClose}
      fullWidth
      headerContent="Template importálás"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth: '1200px',
          },
        },
      }}
    >
      <div className="text-labelColor mt-3">
        <div className="w-[300px] mb-5">
          <AutoCompleteSelectUI
            label={<FormLabelUI text="Templatek" />}
            dataset={autoCompleteOptions.taskList}
            selectedValue={selectedTemplate}
            onChange={(_e, newVal) => {
              setSelectedTemplate(newVal?.value);
            }}
          />
        </div>
        <div className="col-span-2">
          <TemplateItemTable items={items} />
        </div>
        <div className="text-center mt-5">
          <ButtonUI
            text="Importálás"
            className="mr-3 bg-success"
            onClick={() => handleAddTemplate()}
            disabled={!selectedTemplate}
          />
          <ButtonUI text="Bezárás" color="blue-gray" variant="gradient" onClick={handleClose} />
        </div>
      </div>
    </DialogUI>
  );
};

export default TemplateImportDialog;
