import { useState, useEffect, useMemo } from 'react';

import { dataGridPremiumColumns } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import documentService from '../../service/document.service';

const DocumentReports = ({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  autocompleteOptions,
  documentTypeId,
}) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'docNum', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  const [gridState, setGridState] = useState(initialState);

  useEffect(() => {
    getList();
  }, [documentTypeId, gridState]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = () => {
    documentService.getDocuments(gridState, documentTypeId).then((data) => {
      setReports(data);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'docNum',
        headerName: 'Bizonylatszám',
        type: 'string',
      },
      {
        field: 'companyId',
        headerName: 'Cég',
        type: 'singleSelect',
        sortable: false,
        renderCell: (data) => {
          return data.row.company?.companyName;
        },
        valueOptions: autocompleteOptions.company,
      },
      {
        field: 'address',
        headerName: 'Cím',
        type: 'string',
        renderCell: (data) => {
          const { postCode, city, street } = data.row?.company;

          return `${postCode}, ${city}, ${street}`;
        },
      },
      {
        field: 'docDate',
        headerName: 'Kiállítás dátuma',
        type: 'date',
        valueGetter: (_data, row) => {
          return new Date(row.docDate);
        },
      },
      {
        field: 'completionDate',
        headerName: 'Teljesítés dátuma',
        type: 'date',
        valueGetter: (_data, row) => {
          return new Date(row.completionDate);
        },
      },
      {
        field: 'paymentMethod',
        headerName: 'Fizetési mód',
        type: 'string',
        sortable: false,
      },
      {
        field: 'paymentDeadline',
        headerName: 'Fizetési határidő',
        type: 'date',
        valueGetter: (_data, row) => {
          return new Date(row.paymentDeadline);
        },
      },
      {
        field: 'orderNumber',
        headerName: 'Rendelésszám',
        type: 'string',
        sortable: false,
      },
      {
        field: 'paymentDeadline',
        headerName: 'Fizetési határidő',
        type: 'date',
        sortable: false,
      },
      {
        field: 'projectId',
        headerName: 'Projekt',
        type: 'singleSelect',
        sortable: false,
        renderCell: (data) => {
          return data.row.project?.projectName;
        },
        valueOptions: autocompleteOptions.project,
      },
    ]);
  }, [autocompleteOptions]);

  return (
    <>
      {Object.keys(autocompleteOptions).length > 0 && (
        <DatagridPremiumUi
          datagridIdentifier={DataGridIdentifiers.documentReports}
          datagridInitialState={initialState}
          dataLoader={getList}
          rows={reports.rows}
          refetch={{ refetchTable, setRefetchTable }}
          rowCount={reports.rowCount}
          columns={[...columns]}
          onRowClick={(rowData) => {
            if (!viewField) return;
            setSelectedValues(rowData.row);
          }}
          paginationAndfilterMode="server"
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          disableRowSelectionOnClick={!viewField}
          onGridStateChange={setGridState}
        />
      )}
    </>
  );
};

export default DocumentReports;
