import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'jobMonitor';

const jobMonitorService = {
  getProductionOperations: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/productionOperations`,
      data,
    });
  },
};

export default jobMonitorService;
