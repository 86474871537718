import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'taskList';

const taskListService = {
  getTaskListTemplates: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/templates`,
      data,
    });
  },

  createTaskListTemplate: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateTaskListTemplate: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteTaskListTemplate: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  updateTaskListName: (data, id) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/${id}`,
      data,
    });
  },
};

export default taskListService;
