import React, { useEffect, useState } from 'react';
import { useProject } from '../../hooks/useProject';
import { AutoCompleteSelectUI, FormLabelUI } from '../Interface';
import projectService from '../../service/project.service';
import { ProjectPage } from '../Project/ProjectPage';

export const ProjectInfoPanelPage = () => {
  const projectState = useProject();
  const [filterFormState, setFilterFormState] = useState({
    projectId: '',
  });

  useEffect(() => {
    if (filterFormState.projectId) {
      projectService.getProjectById(filterFormState.projectId).then((data) => {
        projectState.setSelectedValues(data);
      });
    }
  }, [filterFormState]);

  return (
    <div className="bg-gray-100  px-1 md:px-5">
      <div className="pb-2">
        <div className="w-full md:w-[250px] pt-2">
          <AutoCompleteSelectUI
            id="Submenus"
            label={<FormLabelUI text="Projekt választó" />}
            selectedValue={filterFormState.projectId}
            table="project"
            listType={{ id: 'projectId', name: 'projectName' }}
            conditions={`project.archive= "N"`}
            onChange={(_e, newVal, reason) => {
              if (reason === 'clear') {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  projectId: '',
                }));
              } else {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  projectId: newVal.value,
                }));
              }
            }}
          />
        </div>
      </div>
      <div className="bg-white rounded">
        <ProjectPage projectState={projectState} infoPanel={true} />
      </div>
    </div>
  );
};
