import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import dayjs from 'dayjs';
import Yup from '../../../utils/yup';
import utc from 'dayjs/plugin/utc';

import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';
import useUsers from '../../../context/UsersContext';
import resourceOneService from '../../../service/resources/resourceOne.service';
import { getDynVals } from '../../../utils/DynHelper';
import propertyService from '../../../service/property.service';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI } from '../../Interface';
import { ResourceOneFields } from './ResourceOneFields';
import ResoureOneTabs from './ResoureOneTabs';
import History from '../../History/History';
import HistoryDialog from '../../History/HistoryDialog';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IconButton } from '@mui/material';
import InfoDialog from './InfoDialog';

export const ResourceOnePage = ({ resourceOneState, dialog }) => {
  const {
    setRefetchTable,
    selectedValues,
    setSelectedValues,
    viewField,
    setViewField,
    operatorsDisabled,
    setOperatorsDisabled,
    submenu,
    canEdit,
    dynProps,
    setDynProps,
    autocompleteOptions,
    dynLoading,
    setDynLoading,
    autocompleteData,
  } = resourceOneState;

  const { getUser, user } = useUsers();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [initValues, setInitValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [propertyGroups, setPropertyGroups] = useState([]);
  const [group, setGroup] = useState({
    id: [],
  });

  const defaultCommunication = {
    id: null,
    isNew: true,
    message: '',
    userId: user.userId,
    userName: user.userName,
    createdAt: null,
  };

  const def = {
    name: '',
    groupId: null,
    active: 1,
    statusId: null,
    typeId: null,
    generatedName: '',
    classificationId: null,
    communication: [],
  };
  useEffect(() => {
    if (!Object.keys(user).length) getUser();

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          statusId: autocompleteData?.status?.defaultValue ?? null,
          typeId: autocompleteData?.type?.defaultValue ?? null,
          groupId: autocompleteData?.resourceGroup?.defaultValue ?? null,
          classificationId: autocompleteData?.classification?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          status: autocompleteData?.status?.defItem,
          type: autocompleteData?.type?.defItem,
          resourceGroup: autocompleteData?.resourceGroup?.defItem,
          classification: autocompleteData?.classification?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    if (submenu) {
      getPropertyGroupsForForm();
    }
  }, [submenu]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const getPropertyGroupsForForm = () => {
    propertyService.getPropertyGroupsForSubmenus({ data: [submenu] }).then((data) => {
      setPropertyGroups(data);
    });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    groupId: Yup.string().nullable(),
    active: Yup.number().nullable(),
    statusId: Yup.string().nullable(),
    typeId: Yup.string().nullable(),
    classificationId: Yup.string().nullable(),
    communication: Yup.array().of(
      Yup.object().shape({
        message: Yup.string().required(),
      })
    ),
  });

  const valuesToForm = () => {
    const { name, groupId, active, statusId, typeId, generatedName, classificationId, resourceOneCommunication } =
      selectedValues;

    setInitValues({
      name,
      groupId,
      active,
      statusId,
      typeId,
      generatedName,
      classificationId,
      dynamicFields: getDynVals(dynProps, selectedValues.dynamicProperties),
      communication: resourceOneCommunication.map((rc) => ({
        id: rc.id,
        message: rc.message,
        userId: rc.user.userId,
        userName: rc.user.userName,
        createdAt: rc.createdAt,
      })),
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    resourceOneService.updateResource(updData, selectedValues.id).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);

      if (dialog) {
        dialog.setTaskChanged(true);
        dialog.handleClose();
      }
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    resourceOneService
      .createResource(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });

        setSelectedValues(data);

        setRefetchTable(true);
        setAddField(true);
        setViewField(true);

        if (dialog) {
          dialog.setTaskChanged(true);
          dialog.handleClose();
        }
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    resourceOneService.deleteResource(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);

      if (dialog) {
        dialog.setTaskChanged(true);
        dialog.handleClose();
      }
    });
  };

  const handleTemplate = () => {
    resourceOneService.getExcelTemplate().then(() => {
      addNotification({
        content: 'A letöltés elindult!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  const handleExport = () => {
    resourceOneService.getExcelData().then(() => {
      addNotification({
        content: 'A letöltés elindult!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  const handleImport = (files) => {
    resourceOneService.importExcel(files, user.userId).then(() => {
      setRefetchTable(true);
      addNotification({
        content: 'Az import sikeres!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  const onAddClick = () => {
    setSelectedValues(defaultSelectedValues);
    setViewField(false);
    setAddField(false);
  };

  return (
    <>
      <div className="h-full overflow-auto custom-scrollbar">
        <div className="flex items-center flex-wrap justify-between">
          <FormHeaderUI
            addClick={!dialog ? onAddClick : null}
            addDisabled={!viewField || !canEdit}
            modClick={() => {
              setViewField(false);
              setEditButtonvisible(false);
            }}
            modDisabled={!viewField || operatorsDisabled || !canEdit}
            deleteClick={() => {
              setShowDeleteDialog(true);
            }}
            deleteDisabled={!viewField || operatorsDisabled || !canEdit}
            copyClick={() => {
              setViewField(false);
              setAddField(false);
            }}
            copyDisabled={!viewField || operatorsDisabled || !canEdit}
          />
          <div>
            <IconButton disabled={operatorsDisabled} onClick={() => setInfoDialogOpen(!infoDialogOpen)}>
              <InfoOutlinedIcon sx={{ fontSize: '1.8rem' }} />
            </IconButton>
            <History disabled={operatorsDisabled} onClick={() => setHistoryDialogOpen(!historyDialogOpen)} />
          </div>
        </div>

        <Formik
          initialValues={initValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ setFieldValue, resetForm }) => (
            <Form>
              <ResourceOneFields
                viewField={viewField}
                submenuId={submenu}
                selectedValues={selectedValues}
                autocompleteOptions={autocompleteOptions}
              />
              <ResoureOneTabs
                disabled={viewField}
                submenuId={submenu}
                setFieldValue={setFieldValue}
                selectedValues={selectedValues}
                setDefaultValues={setDefaultValues}
                dynProperties={{ dynProps, setDynProps }}
                user={user}
                setRefetchTable={setRefetchTable}
                handleImport={handleImport}
                handleExport={handleExport}
                handleTemplate={handleTemplate}
                propertyGroups={propertyGroups}
                groupState={{ group, setGroup }}
                defaultField={defaultCommunication}
                dynLoadingState={{ dynLoading, setDynLoading }}
                setSelectedValues={setSelectedValues}
              />

              <FormButtonPanelUI
                onCancelClick={() => {
                  setViewField(true);
                  setEditButtonvisible(true);
                  setAddField(true);

                  resetForm();
                }}
                disabled={editButtonVisible && addField}
              />
            </Form>
          )}
        </Formik>
      </div>
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
      <HistoryDialog
        open={historyDialogOpen}
        handleClose={() => {
          setHistoryDialogOpen(false);
        }}
        formId={submenu}
        lineId={selectedValues.id}
        fieldConfig={[
          { field: 'resourceOneCommunication', objKey: 'message' },
          { field: 'dynamicProperties', objKey: 'textVal' },
        ]}
      />

      <InfoDialog open={infoDialogOpen} handleClose={() => setInfoDialogOpen(false)} selectedValues={selectedValues} />
    </>
  );
};
