export const LeaderReportType = {
  NORMAL: 'NORMAL',
  TWOMACHINE: 'TWOMACHINE',
  PREMIUM: 'PREMIUM',
  OVERTIME: 'OVERTIME',
};

export const LeaderReportTypeList = [
  { label: 'Normál', value: LeaderReportType.NORMAL },
  { label: 'Két gépes pótlék', value: LeaderReportType.TWOMACHINE },
  { label: 'Prémium', value: LeaderReportType.PREMIUM },
  { label: 'Túlóra', value: LeaderReportType.OVERTIME },
];

export const LeaderReportShift = {
  FIRST: 'FIRST',
  SECOND: 'SECOND',
  THIRD: 'THIRD',
};

export const LeaderReportShiftList = [
  { label: '1', value: LeaderReportShift.FIRST },
  { label: '2', value: LeaderReportShift.SECOND },
  { label: '3', value: LeaderReportShift.THIRD },
];

export const LeaderReportActivity = {
  FREEDOM: 'FREEDOM',
  POCKETMONEY: 'POCKETMONEY',
  CERTIFIED: 'CERTIFIED',
  PAID_HOLIDAY: 'PAID_HOLIDAY',
  TMK: 'TMK',
  KFT: 'KFT',
  NOT_CERTIFIED: 'NOT_CERTIFIED',
};

export const LeaderReportActivityList = [
  { label: 'Szabadság', value: LeaderReportActivity.FREEDOM },
  { label: 'Táppénz', value: LeaderReportActivity.POCKETMONEY },
  { label: 'Ig. Táv', value: LeaderReportActivity.CERTIFIED },
  { label: 'Fiz. Ünnep', value: LeaderReportActivity.PAID_HOLIDAY },
  { label: 'TMK', value: LeaderReportActivity.TMK },
  { label: 'KFT. Ált.', value: LeaderReportActivity.KFT },
  { label: 'Nem igazolt', value: LeaderReportActivity.NOT_CERTIFIED },
];
