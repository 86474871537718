import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { LicenseInfo } from '@mui/x-license';
import { createTheme, ThemeProvider } from '@mui/material';

import NoPage from './components/NoPage/NoPage';
import Layout from './components/Navbar/Layout';
import { StatusForm } from './components/Status/StatusForm';
import Auth from './components/Auth/Auth';
import ProtectedRoute from './components/ProtectedRoute';
import RoleIndex from './components/Roles/RoleIndex';
import { SimpleTaskMain } from './components/NewSimpleTask/SimpleTaskMain';
import { ProjectGroupForm } from './components/ProjectGroupListitems/ProjectGroupForm';
import { PriorityForm } from './components/Priority/PriorityForm';
import { UserForm } from './components/Users/UserForm';
import { MachineForm } from './components/Machines/MachineForm';
import TypeForm from './components/Type/TypeForm';
import MyTodoTable from './components/MyTodo/MyTodoTable';
import { LoaderProvider } from './provider/LoaderProvider';
import LoaderUI from './components/Interface/LoaderUI';
import KanbanView from './components/Kanban/KanbanView';
import TaskevaluationForm from './components/TaskEvaluation/TaskevaluationForm';
import TaskGanttForm from './components/TaskGantt/TaskGanttForm';
import { SimpleTaskPhoneForm } from './components/SimpleTaskPhone/SimpleTaskPhoneForm';
import SapResourcePlanner from './components/SapResourcePlanner/SapResourcePlanner';
import ActiveTimePage from './components/ActiveTime/ActiveTimePage';
import { OperationForm } from './components/Operation/OperationForm';
import { QuantityUnitForm } from './components/QuantityUnit/QuantityUnitForm';
import { TaskReportForm } from './components/TaskReport/TaskReportForm';
import { ProductionReportForm } from './components/ProductionReport/ProductionReportForm';
import { CompanyLocationForm } from './components/CompanyLocation/CompanyLocationForm';
import { PropertyForm } from './components/Property/PropertyForm';
import { InnovationForm } from './components/Innovation/InnovationForm';
import { AttendancePage } from './components/Attendance/AttendancePage';
import { AbsencePage } from './components/Absence/AbsencePage';
import { ResourceTwoPage } from './components/Resources/ResourceTwo/ResourceTwoPage';
import { ResourceThreePage } from './components/Resources/ResourceThree/ResourceThreePage';
import { ResourceFourPage } from './components/Resources/ResourceFour/ResourceFourPage';
import { ProjectResourcePlanner } from './components/ProjectResourcePlanner/ProjectResourcePlanner';
import { EmployeeResourcePlanner } from './components/EmployeeResourcePlanner/EmployeeResourcePlanner';
import { ProjectInfoPanelPage } from './components/ProjectInfoPanel/ProjectInfoPanelPage';
import { UserProfilePage } from './components/UserProfile/UserProfilePage';
import { ComplaintPage } from './components/ProductComplaint/ComplaintPage';
import { FunnelView } from './components/Kanban/FunnelView';
import BiDataSourcePage from './components/BusinessIntelligence/BiDataSource/BiDataSourcePage';
import BiEditorPage from './components/BusinessIntelligence/BiEditor/BiEditorPage';
import BiQueryPage from './components/BusinessIntelligence/BiQueries/BiQueryPage';
import BiPage from './components/BusinessIntelligence/BiReport/BiPage';
import Dashboard from './components/BusinessIntelligence/Dashboard/Dashboard';
import DashboardAdmin from './components/BusinessIntelligence/Dashboard/DashboardAdmin';
import { ClassificationPage } from './components/Classification/ClassificationPage';
import { ProjectMain } from './components/Project/ProjectMain';
import { CalendarPage } from './components/Calendar/CalendarPage';
import { CrmTaskMain } from './components/CrmTask/CrmTaskMain';
import { PropertyGroupPage } from './components/PropertyGroup/PropertyGroupPage';
import { VehiclePlanPage } from './components/VehiclePlan/VehiclePlanPage';
import { VehicleResourcePlanner } from './components/VehiclePlan/VehiclePlanner/VehicleResourcePlanner';
import { HistoryPage } from './components/History/HistoryPage';
import SourceListPage from './components/SourceList/SourceListPage';

import ProductionMain from './components/Production/NewProduction/ProductionMain';
import { ItemPage } from './components/Item/ItemPage';
import { CheckListTemplatePage } from './components/CheckList/Template/CheckListTemplatePage';
import { CompanyPage } from './components/Company/CompanyPage';
import SapShiftLeaderReportPage from './components/SapShiftLeaderReport/SapShiftLeaderReportPage';
import ContactMapPage from './components/CrmTask/ContactMap/ContactMapPage';
import { PlanAbsencePage } from './components/PlanAbsence/PlanAbsencePage';
import { DocumentTypePage } from './components/Document/DocumentType/DocumentTypePage';
import { DocumentPage } from './components/Document/DocumentPage';
import { VatPage } from './components/Vat/VatPage';
import TaskListPlannerPage from './components/TaskListResourcePlanner/TaskListPlannerPage';
import { TaskListTemplatePage } from './components/TaskList/Template/TaskListTemplatePage';
import CurrencyPage from './components/Currency/CurrencyPage';
import VatCodePage from './components/VatCode/VatCodePage';
import CountryPage from './components/Country/CountryPage';
import LanguagePage from './components/Language/LanguagePage';
import { ResourceOneMain } from './components/Resources/NewResourceOne/ResourceOneMain';
import { JobMonitorPage } from './components/Jobmonitor/JobMonitorPage';

import { MenuProvider } from './context/MenuContext';
import { UsersProvider } from './context/UsersContext';
import { PageProvider } from './context/PageContext';
import { ParamProvider } from './context/ParamContext';

LicenseInfo.setLicenseKey(
  '81d488d88ad4c04083128f2797c95f83Tz05OTk2MyxFPTE3NjA1MzYxNDUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI='
);

const App = () => {
  const theme = createTheme();
  return (
    <LoaderProvider>
      <PageProvider>
        <MenuProvider>
          <ParamProvider>
            <UsersProvider>
              <ThemeProvider theme={theme}>
                <LoaderUI />
                <SnackbarProvider hideIconVariant>
                  <BrowserRouter>
                    <Routes>
                      <Route path="/" element={<Auth />} />
                      <Route path="/app" element={<ProtectedRoute element={<Layout />} />}>
                        <Route index element={<MyTodoTable />} />
                        <Route path="project" element={<ProjectMain />} />
                        <Route path="company" element={<CompanyPage />} />
                        <Route path="status" element={<StatusForm />} />
                        <Route path="projectGroup" element={<ProjectGroupForm />} />
                        <Route path="Categories" element={<PriorityForm />} />
                        <Route path="type" element={<TypeForm />} />
                        <Route path="machine" element={<MachineForm />} />
                        <Route path="operation" element={<OperationForm />} />
                        <Route path="quantityunit" element={<QuantityUnitForm />} />
                        <Route path="companylocation" element={<CompanyLocationForm />} />
                        <Route path="properties" element={<PropertyForm />} />
                        <Route path="complaint" element={<ComplaintPage />} />
                        <Route path="classification" element={<ClassificationPage />} />
                        <Route path="propertyGroup" element={<PropertyGroupPage />} />
                        <Route path="vehiclePlan" element={<VehiclePlanPage />} />
                        <Route path="item" element={<ItemPage />} />
                        <Route path="checkListTemplate" element={<CheckListTemplatePage />} />
                        <Route path="taskListTemplate" element={<TaskListTemplatePage />} />
                        <Route path="sourcelist" element={<SourceListPage />} />
                        <Route path="documentType" element={<DocumentTypePage />} />
                        <Route path="document/:documentTypeId" element={<DocumentPage />} />
                        <Route path="vat" element={<VatPage />} />
                        <Route path="currency" element={<CurrencyPage />} />
                        <Route path="vatCode" element={<VatCodePage />} />
                        <Route path="country" element={<CountryPage />} />
                        <Route path="language" element={<LanguagePage />} />

                        <Route path="resourceOne" element={<ResourceOneMain />} />
                        <Route path="resourceTwo" element={<ResourceTwoPage />} />
                        <Route path="resourceThree" element={<ResourceThreePage />} />
                        <Route path="resourceFour" element={<ResourceFourPage />} />

                        <Route path="simpleTask" element={<SimpleTaskMain />} />
                        <Route path="mytodo" element={<MyTodoTable />} />
                        <Route path="simpleTaskPhone" element={<SimpleTaskPhoneForm />} />
                        <Route path="production" element={<ProductionMain />} />
                        <Route path="taskReport" element={<TaskReportForm />} />
                        <Route path="productionreport/:prodOperationId?" element={<ProductionReportForm />} />
                        <Route path="innovation" element={<InnovationForm />} />
                        <Route path="absence" element={<AbsencePage />} />
                        <Route path="attendance" element={<AttendancePage />} />
                        <Route path="crmTask/:id?" element={<CrmTaskMain />} />
                        <Route path="planAbsence" element={<PlanAbsencePage />} />
                        <Route path="jobMonitor" element={<JobMonitorPage />} />

                        <Route path="user" element={<UserForm />} />
                        <Route path="roles" element={<RoleIndex />} />

                        <Route path="projectResourcePlanner" element={<ProjectResourcePlanner />} />
                        <Route path="employeeResourcePlanner" element={<EmployeeResourcePlanner />} />
                        <Route path="projectInfoPanel" element={<ProjectInfoPanelPage />} />
                        <Route path="userProfile" element={<UserProfilePage />} />
                        <Route path="calendarView" element={<CalendarPage />} />
                        <Route path="vehiclePlanner" element={<VehicleResourcePlanner />} />
                        <Route path="taskListResourcePlanner" element={<TaskListPlannerPage />} />

                        <Route path="taskkanban" element={<KanbanView />} />
                        <Route path="funnelView" element={<FunnelView />} />
                        <Route path="taskevaluation" element={<TaskevaluationForm />} />
                        <Route path="taskgantt" element={<TaskGanttForm />} />
                        <Route path="contactMap/:id?" element={<ContactMapPage />} />

                        <Route path="activeTime" element={<ActiveTimePage />} />
                        <Route path="sapresourceplanner" element={<SapResourcePlanner />} />
                        <Route path="sapshiftleaderreport" element={<SapShiftLeaderReportPage />} />

                        <Route path="history" element={<HistoryPage />} />

                        <Route path="bi">
                          <Route path="datasource" element={<BiDataSourcePage />} />
                          <Route path="query" element={<BiQueryPage />} />
                          <Route path="editor/:reportId?" element={<BiEditorPage />} />
                          <Route path="biPage/:reportId" element={<BiPage />} />
                        </Route>
                        <Route path="dashboard/:dashboardId" element={<Dashboard />} />
                        <Route path="dashboardAdmin" element={<DashboardAdmin />} />

                        <Route path="*" element={<NoPage />} />
                      </Route>
                    </Routes>
                  </BrowserRouter>
                </SnackbarProvider>
              </ThemeProvider>
            </UsersProvider>
          </ParamProvider>
        </MenuProvider>
      </PageProvider>
    </LoaderProvider>
  );
};

export default App;
