import request from '../utils/Request';
import { Methods } from '../config';
import { downloadFile } from '../utils/download';

const baseURL = 'document';

const documentService = {
  getDocuments: (data, documentTypeId) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/documents/${documentTypeId}`,
      data,
    });
  },

  createDocument: (data, documentTypeId) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/${documentTypeId}`,
      data,
    });
  },

  updateDocument: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteDoument: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  printDocument: async (data) => {
    const pdf = await request({
      responseType: 'blob',
      method: Methods.POST,
      resource: `${baseURL}/print`,
      data,
    });

    console.log(pdf);
    return downloadFile(pdf, pdf.filename);
  },
};

export default documentService;
