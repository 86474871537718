import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';

import Yup from '../../../utils/yup';
import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';
import useUsers from '../../../context/UsersContext';
import documentTypeService from '../../../service/documentType.service';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI, FormBase } from '../../Interface';
import DocumentTypeFields from './DocumentTypeFields';
import DocumentTypeReports from './DocumentTypeReports';
import DocumentTypeTabs from './DocumentTypeTabs';

export const DocumentTypePage = () => {
  const { getUser, user } = useUsers();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const defaultValues = {
    name: '',
    prefix: '',
    direction: '',
  };

  useEffect(() => {
    if (!Object.keys(user).length) getUser();

    setInitValues(defaultValues);
  }, []);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (selectedValues) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const valuesToForm = () => {
    const { name, prefix, direction, printReport } = selectedValues;

    setInitValues({
      name,
      prefix,
      direction,
      printReport,
    });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    prefix: Yup.string().required().max(4),
    direction: Yup.string().required(),
  });

  const insertObject = (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    documentTypeService
      .createDocumentType(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setSelectedValues(data);
        setRowSelectionModel(data.id);
      })

      .finally(() => {
        //@TODO loader
      });
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    documentTypeService.updateDocumentType(updData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      insertObject(values);
    }
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    documentTypeService.deleteDoumentType(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues({});
    });
  };

  return (
    <>
      <FormBase
        name="DocumentType"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <FormHeaderUI
                addClick={() => {
                  setSelectedValues({});
                  setViewField(false);
                  setAddField(false);
                }}
                addDisabled={!viewField}
                modClick={() => {
                  setViewField(false);
                  setEditButtonvisible(false);
                }}
                modDisabled={!viewField || operatorsDisabled}
                deleteClick={() => {
                  setShowDeleteDialog(true);
                }}
                deleteDisabled={!viewField || operatorsDisabled}
                copyClick={() => {
                  setViewField(false);
                  setAddField(false);
                }}
                copyDisabled={!viewField || operatorsDisabled}
              />

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ values, errors, resetForm, validateField, setFieldValue }) => (
                  <Form>
                    <DocumentTypeFields
                      viewField={viewField}
                      selectedValues={selectedValues}
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      validateField={validateField}
                    />

                    <DocumentTypeTabs
                      user={user}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                      disabled={viewField}
                      setRefetchTable={setRefetchTable}
                    />

                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);
                        resetForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <>
            <DocumentTypeReports
              refetch={{ refetchTable, setRefetchTable }}
              values={{ selectedValues, setSelectedValues }}
              viewField={viewField}
              operatorsDisabled={operatorsDisabled}
              rowSelection={{ rowSelectionModel, setRowSelectionModel }}
            />
          </>
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
