import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'history';

const historyService = {
  getHistoriesById: (formId, lineId) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/${formId}/${lineId}`,
    });
  },
};

export default historyService;
