import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import Yup from '../../utils/yup';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import useUsers from '../../context/UsersContext';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI, FormBase, ButtonUI } from '../Interface';
import DocumentFields from './DocumentFields';
import DocumentReports from './DocumentReports';
import { generateAutocompleteItems } from '../../utils/helper';
import documentService from '../../service/document.service';
import masterDataService from '../../service/masterData.service';
import DocumentTabs from './DocumentTabs';
import documentTypeService from '../../service/documentType.service';

export const DocumentPage = () => {
  // @TODO legyen hidden a typeid
  const { documentTypeId } = useParams();
  const { getUser, user } = useUsers();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autocompleteData, setAutoCompleteData] = useState({});
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    company: [],
    project: [],
    item: [],
    qtyUnit: [],
    vat: [],
  });
  const [documentData, setDocumentData] = useState({});

  const defaultDocumentLine = {
    id: null,
    isNew: true,
    itemCode: '',
    qty: '',
    qtyUnit: '',
    netUnitPrice: '',
    grossUnitPrice: '',
    vat: '',
    comment: '',
    projectId: null,
    createdBy: user.userId,
  };

  const defaultValues = {
    companyId: null,
    postCode: '',
    city: '',
    street: '',
    docDate: dayjs(new Date()),
    completionDate: dayjs(new Date()),
    paymentMethod: '',
    paymentDeadline: dayjs(new Date()).add(8, 'day'),
    orderNumber: '',
    projectId: null,
    docNum: '',
    fullAddress: '',
    documentLine: [],
  };

  useEffect(() => {
    if (!Object.keys(user).length) getUser();

    setInitValues(defaultValues);
    initDefaultValues();
  }, []);

  useEffect(() => {
    setInitValues(defaultValues);
    setOperatorsDisabled(true);
    documentTypeService.getDocumentType(documentTypeId).then((data) => setDocumentData(data));
  }, [documentTypeId]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (selectedValues) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .companyToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .projectToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .itemsToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .quantityUnitToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .vatToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          company: resp[0],
          project: resp[1],
          item: resp[2],
          qtyUnit: resp[3],
          vat: resp[4],
        };

        setAutoCompleteData(aData);

        const acOptions = {
          company: generateAutocompleteItems(aData.company, 'companyName', 'companyId'),
          project: generateAutocompleteItems(aData.project, 'projectName', 'projectId'),
          item: generateAutocompleteItems(aData.item, ['itemCode', 'itemName'], 'itemCode'),
          qtyUnit: generateAutocompleteItems(aData.qtyUnit, 'quantityUnitName', 'quantityUnitId'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {});
  };

  const valuesToForm = () => {
    const {
      companyId,
      postCode,
      city,
      street,
      docDate,
      completionDate,
      paymentMethod,
      paymentDeadline,
      orderNumber,
      projectId,
      createdBy,
      docNum,
      documentLine,
      fullAddress,
    } = selectedValues;

    setInitValues({
      companyId,
      postCode,
      city,
      street,
      docDate: dayjs(docDate),
      completionDate: dayjs(completionDate),
      paymentMethod,
      paymentDeadline: dayjs(paymentDeadline),
      orderNumber,
      projectId,
      docNum: docNum,
      createdBy,
      fullAddress,
      documentLine: documentLine.map((dL) => ({
        id: dL.id,
        itemCode: dL.itemCode,
        qty: dL.qty,
        qtyUnit: dL.qtyUnit,
        netUnitPrice: dL.netUnitPrice,
        grossUnitPrice: dL.grossUnitPrice,
        vat: dL.vat,
        comment: dL.comment,
        projectId: dL.projectId,
        createdBy: dL.createdBy,
      })),
    });
  };

  const validationSchema = Yup.object().shape({
    companyId: Yup.string().required(),
    postCode: Yup.string().nullable(),
    city: Yup.string().nullable(),
    street: Yup.string().nullable(),
    docDate: Yup.string().required(),
    completionDate: Yup.string().required(),
    paymentMethod: Yup.string().required(),
    paymentDeadline: Yup.string().required(),
    orderNumber: Yup.string().nullable(),
    projectId: Yup.string().nullable(),
    documentLine: Yup.array().of(
      Yup.object().shape({
        itemCode: Yup.string().required(),
        qty: Yup.number().positive().required(),
        qtyUnit: Yup.string().required(),
        netUnitPrice: Yup.number().required(),
        grossUnitPrice: Yup.number().required(),
        vat: Yup.number().required(),
        comment: Yup.string().nullable(),
        projectId: Yup.string().nullable(),
      })
    ),
  });

  const insertObject = (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
      docDate: dayjs(values.docDate).toISOString(),
      paymentDeadline: dayjs(values.paymentDeadline).toISOString(),
    };

    documentService
      .createDocument(insertObject, documentTypeId)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setSelectedValues(data);
        setRowSelectionModel(data.id);
      })

      .finally(() => {
        //@TODO loader
      });
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
      docDate: dayjs(values.docDate).toISOString(),
      paymentDeadline: dayjs(values.paymentDeadline).toISOString(),
    };

    documentService.updateDocument(updData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      insertObject(values);
    }
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    documentService.deleteDoument(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues({});
    });
  };

  const printClick = () => {
    if (documentData?.printReport?.length === 0) {
      return addNotification({
        content: 'Nem áll rendelkezésre nyomtatási kép!',
        type: NotificationType.WARNING,
      });
    }

    addNotification({
      content: 'Nyomtatás elindult!',
      type: NotificationType.INFO,
    });

    documentService.printDocument({
      docId: selectedValues.id,
      documentTypeId,
      printReportId: documentData?.printReport[0]?.id,
    });
  };

  return (
    <>
      <FormBase
        name="Document"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <div className="flex items-center flex-wrap">
                <FormHeaderUI
                  addClick={() => {
                    setSelectedValues({});
                    setViewField(false);
                    setAddField(false);
                  }}
                  addDisabled={!viewField}
                  modClick={() => {
                    setViewField(false);
                    setEditButtonvisible(false);
                  }}
                  modDisabled={!viewField || operatorsDisabled}
                  deleteClick={() => {
                    setShowDeleteDialog(true);
                  }}
                  deleteDisabled={!viewField || operatorsDisabled}
                  copyClick={() => {
                    setViewField(false);
                    setAddField(false);
                  }}
                  copyDisabled={!viewField || operatorsDisabled}
                />

                <ButtonUI
                  text="Nyomtatás"
                  size="sm"
                  className="bg-labelColor py-3"
                  onClick={() => printClick()}
                  disabled={!viewField || operatorsDisabled}
                />
              </div>

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ values, errors, resetForm, validateField, setFieldValue }) => (
                  <Form>
                    <DocumentFields
                      viewField={viewField}
                      selectedValues={selectedValues}
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      validateField={validateField}
                      autocompleteOptions={autocompleteOptions}
                      autocompleteData={autocompleteData}
                    />

                    <DocumentTabs
                      setRefetchTable={setRefetchTable}
                      selectedValues={selectedValues}
                      user={user}
                      disabled={viewField}
                      defaultField={defaultDocumentLine}
                      autocompleteOptions={autocompleteOptions}
                      autocompleteData={autocompleteData}
                    />

                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);
                        resetForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <>
            <DocumentReports
              refetch={{ refetchTable, setRefetchTable }}
              values={{ selectedValues, setSelectedValues }}
              viewField={viewField}
              operatorsDisabled={operatorsDisabled}
              rowSelection={{ rowSelectionModel, setRowSelectionModel }}
              autocompleteOptions={autocompleteOptions}
              documentTypeId={documentTypeId}
            />
          </>
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
