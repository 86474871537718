import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { ReactFlow, useNodesState, useEdgesState, addEdge, Controls, MarkerType } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import crmTaskService from '../../../service/crmTask.service';
import { generateAutocompleteItems, generateFilter } from '../../../utils/helper';
import { AutoCompleteSelectUI, ButtonUI, FormLabelUI } from '../../Interface';
import masterDataService from '../../../service/masterData.service';
import { ColorPicker } from '../../../config/ColorPicker';
import CrmTaskDialog from '../CrmTaskDialog';
import { useNavigate, useParams } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { IconButton } from '@mui/material';
import useMenus from '../../../context/MenuContext';
import usePages from '../../../context/PageContext';

const snapGrid = [20, 20];

const connectionLineStyle = {
  stroke: '#b1b1b7',
};

const defaultEdgeOptions = {
  type: 'floating',
  markerEnd: {
    type: MarkerType.ArrowClosed,
    width: 17,
    height: 17,
    color: 'rgb(53, 53, 53)',
  },
};

const ContactMapPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { menus, getSubMenuNamesByUrls } = useMenus();
  const { setPages } = usePages();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [task, setTask] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [filterFormState, setFilterFormState] = useState({
    id: '',
  });
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    events: [],
  });
  const [query, setQuery] = useState({
    filter: [],
  });

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['crmTask']);
  }, [menus]);

  useEffect(() => {
    if (id) {
      setFilterFormState((prev) => ({
        ...prev,
        id: parseInt(id),
      }));
    }
  }, [id]);

  const conditions = {};

  useEffect(() => {
    if (dataChanged) {
      getAllContactMap();
    }
  }, [dataChanged]);

  useEffect(() => {
    initDefaultValues();
  }, []);

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'deletedBy',
        value: null,
      },
    ];

    if (filterFormState.id) {
      const newFilter = generateFilter(filterFormState, conditions);
      setQuery((prev) => {
        return { ...prev, filter: [...defaultFilter, ...newFilter] };
      });
    }
  }, [filterFormState]);

  useEffect(() => {
    if (Object.keys(query).length > 0 && query.filter?.length > 0 && filterFormState.id !== '') {
      getAllContactMap();
    }
  }, [query, filterFormState.id]);

  const generateColorToNode = (colorName) => {
    const colorObject = ColorPicker.find((item) => item.colorName === colorName);
    return colorObject;
  };

  const getAllContactMap = () => {
    crmTaskService.getContactMaps(query).then(({ edges, nodes }) => {
      const styledNodes = nodes.map((node) => {
        const isMatching = filterFormState.id === parseInt(node.id);
        const colors = generateColorToNode(node.data.color);

        return {
          ...node,
          style: {
            background: isMatching ? 'rgba(249, 168, 212, 0.6)' : colors?.colorBgCode,
            border: isMatching
              ? '1px solid rgba(236, 72, 153, 0.4)'
              : `1px solid ${colors?.kanbanChipBgColor || 'rgba(113, 113, 122, 0.4)'}`,
            color: isMatching ? 'rgba(219, 39, 119, 1)' : colors?.textColor,
          },
        };
      });

      setNodes(styledNodes);
      setEdges(edges);
    });
  };

  const initDefaultValues = () => {
    masterDataService
      .crmEventsToForm()
      .then((data) => {
        const aData = {
          events: data,
        };

        const acOptions = {
          events: generateAutocompleteItems(aData.events, 'name', 'id'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {});
  };

  const onNodeMouseEnter = (_event, node) => {
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === node.id && !n.data.isSelected) {
          const colorObject = generateColorToNode(n.data.color);
          const isMatching = filterFormState.id === parseInt(node.id);
          return {
            ...n,
            style: {
              ...n.style,
              border: isMatching
                ? '1px solid rgba(236, 72, 153, 1)'
                : `1px solid ${colorObject?.textColor || 'rgba(113, 113, 122, 1)'}`,
            },
          };
        }
        return n;
      })
    );
  };

  const onNodeMouseLeave = (_event, node) => {
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === node.id && !n.data.isSelected) {
          const isMatching = filterFormState.id === parseInt(n.id);
          const colorObject = generateColorToNode(n.data.color);

          return {
            ...n,
            style: {
              ...n.style,
              border: isMatching
                ? '1px solid rgba(236, 72, 153, 0.4)'
                : `1px solid ${colorObject?.kanbanChipBgColor || 'rgba(113, 113, 122, 0.4)'}`,

              color: isMatching ? 'rgba(219, 39, 119, 1)' : colorObject?.textColor,
            },
          };
        }
        return n;
      })
    );
  };

  const onNodeClick = (_event, node) => {
    crmTaskService.getTaskById(node.id).then((data) => {
      setIsDialogOpen(true);
      setTask(data);
    });

    setNodes((nds) =>
      nds.map((n) => {
        const isMatching = filterFormState.id === parseInt(n.id);
        if (n.id === node.id) {
          const colorObject = generateColorToNode(n.data.color);
          return {
            ...n,
            style: {
              ...n.style,
              boxShadow: 'none',
              border: isMatching
                ? '1px solid rgba(236, 72, 153, 1)'
                : `1px solid ${colorObject?.textColor || 'rgb(53, 53, 53)'}`,
            },
            data: { ...n.data, isSelected: true },
          };
        }
        return n;
      })
    );
  };

  const handleBack = () => {
    setPages({ subMenuName: submenuNames });
    navigate(`/app/crmTask/${id}`);
  };

  const onConnect = useCallback((params) => setEdges((eds) => addEdge({ ...params, animated: true }, eds)), []);

  const findedLabel = autocompleteOptions.events.find((event) => event.value === filterFormState.id);

  return (
    <>
      <div className="md:left-80 z-40 fixed top-5 left-5 md:top-2">
        <div className="flex flex-col md:flex-row w-full md:gap-10">
          <div className="w-[250px]">
            <AutoCompleteSelectUI
              variant="standard"
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prev) => ({
                    ...prev,
                    id: '',
                  }));
                  setNodes([]);
                  setEdges([]);
                } else {
                  setFilterFormState((prev) => ({
                    ...prev,
                    id: newVal.value,
                  }));
                }
              }}
              selectedValue={filterFormState.id}
              selectedLabelValue={findedLabel?.label ?? ''}
              label={<FormLabelUI text="Esemény" />}
              dataset={autocompleteOptions.events}
            />
          </div>
        </div>
      </div>
      {id && (
        <div className="fixed z-40 m-2">
          <IconButton size="small" onClick={handleBack}>
            <div className="text-sm font-medium py-1.5 px-2 rounded flex items-center justify-center gap-1 bg-ganttHoverButtonColor text-white">
              <KeyboardBackspaceIcon className="text-xl" />
              <span className="text-base">Vissza</span>
            </div>
          </IconButton>
        </div>
      )}
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        snapToGrid={true}
        onNodeMouseEnter={onNodeMouseEnter}
        onNodeMouseLeave={onNodeMouseLeave}
        onNodeClick={onNodeClick}
        style={{ background: '#f3f4f6' }}
        snapGrid={snapGrid}
        defaultEdgeOptions={defaultEdgeOptions}
        fitView
        connectionLineStyle={connectionLineStyle}
        attributionPosition="bottom-left"
      >
        <Controls />
      </ReactFlow>
      <CrmTaskDialog
        open={isDialogOpen}
        handleClose={() => setIsDialogOpen(false)}
        task={task}
        setTaskChanged={setDataChanged}
      />
    </>
  );
};

export default ContactMapPage;
