import React from 'react';

import ProjectReports from './ProjectReports';
import { useProject } from '../../hooks/useProject';
import { ProjectPage } from './ProjectPage';
import { FormBase } from '../Interface';

export const ProjectMain = () => {
  const projectState = useProject();

  return (
    <FormBase
      name="project"
      leftPanelContent={<ProjectPage projectState={projectState} />}
      rightPanelContent={<> {!projectState.isLoading && <ProjectReports projectState={projectState} />}</>}
    />
  );
};
