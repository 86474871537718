import dayjs from 'dayjs';
import request from '../utils/Request';
import { Methods } from '../config';
import { downloadFile } from '../utils/download';

const baseURL = 'sapshiftleaderreport';

const sapShiftLeaderReportService = {
  getSapShiftLeaderReports: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/sapshiftleaderreports`,
      data,
    });
  },

  updateSapShiftLeaderReport: (data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}`,
      data,
    });
  },

  createSapShiftLeaderReport: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  deleteSapShiftLeaderReport: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  getSapShiftLeaderReportById: (id) => {
    return request({
      resource: `${baseURL}/${id}`,
    });
  },

  getSapProject: () => {
    return request({
      resource: `${baseURL}/sapProject`,
    });
  },

  getResources: () => {
    return request({
      resource: `${baseURL}/resources`,
    });
  },
  getEmployees: () => {
    return request({
      resource: `${baseURL}/employees`,
    });
  },

  getProjectById: (projectId) => {
    return request({
      resource: `${baseURL}/projectById/${projectId}`,
    });
  },

  getOperations: () => {
    return request({
      resource: `${baseURL}/operations`,
    });
  },

  getPdf: async (data) => {
    const pdf = await request({
      responseType: 'blob',
      resource: `${baseURL}/printReport`,
      method: Methods.POST,
      data,
    });
    return downloadFile(pdf, `Riport-${dayjs().format('YYYYMMDD_HHmm')}.pdf`);
  },
};
export default sapShiftLeaderReportService;
