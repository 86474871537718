import React from 'react';
import { ButtonUI, DialogUI, InputUI } from '../Interface';

const ModifyDialog = ({ open, handleClose, values, handleChangeName }) => {
  const { selectedValues, setSelectedValues } = values;
  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerColor="grey"
      headerContent="Név módosítása"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '500px',
            background: '#F8F9FA',
          },

          '& .MuiDialogTitle-root': {
            background: '#FFF0D9',
          },
        },
      }}
    >
      <div className="mt-5">
        <InputUI
          label="Név"
          variant="standard"
          value={selectedValues.name}
          onChange={(e) =>
            setSelectedValues((prev) => ({
              ...prev,
              name: e.target.value,
            }))
          }
        />

        <div className="text-center mt-4">
          <ButtonUI
            text="Mentés"
            disabled={!selectedValues.name}
            className="bg-[#F1F2F4] text-[#B0B1B2] border-[#B0B1B2] border rounded-none"
            size="sm"
            onClick={() => handleChangeName()}
          />
        </div>
      </div>
    </DialogUI>
  );
};

export default ModifyDialog;
