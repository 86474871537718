import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const DialogUI = (props) => {
  const { open, headerContent, children, onClose, headerColor } = props;

  return (
    <Dialog open={open} onClose={onClose} {...props}>
      <DialogTitle className="bg-labelColor">
        {headerContent && (
          <h2 className={`${headerColor ? `text-${headerColor}` : 'text-white'} py-1 px-3 rounded`}>{headerContent}</h2>
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default DialogUI;
