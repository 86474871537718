import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const TemplatesTable = ({ selected, templates, onTemplateDoubleClick }) => {
  const { selectedTemplate, setSelectedTemplate } = selected;
  return (
    <TableContainer component={Paper} className="h-[550px] custom-scrollbar">
      <Table>
        <TableHead className="bg-tableHeaderColor">
          <TableRow>
            <TableCell style={{ width: '600px' }}>Templatek</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiTableCell-body': {
              padding: '10px 0px 10px 15px',
            },
          }}
        >
          {templates.map((template) => (
            <TableRow
              key={template.id}
              onClick={() => setSelectedTemplate(template.id)}
              onDoubleClick={() => onTemplateDoubleClick(template)}
              sx={{
                cursor: 'pointer',
                backgroundColor: template.id === selectedTemplate ? '#f0f0f0' : 'inherit',
              }}
            >
              <TableCell>{template.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TemplatesTable;
