import { LinearProgress, Box, Typography } from '@mui/material';
import React from 'react';

const LinearStaticProgressUI = (props) => {
  const { value, bgColor } = props;
  return (
    <Box sx={{ position: 'relative' }}>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          width: '100%',
          backgroundColor: '#E0E0E0',
          borderRadius: 5,
          height: 15,
          '& .MuiLinearProgress-bar': {
            backgroundColor: bgColor ?? 'grey',
          },
        }}
      />
      <Box sx={{ position: 'absolute', top: '-2px', left: '50%' }}>
        <Typography sx={{ fontSize: '0.9rem' }}>{`${value}%`}</Typography>
      </Box>
    </Box>
  );
};

export default LinearStaticProgressUI;
