import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'gantt/sapresourceplanner';

const sapResourcePlannerService = {
  getProductionOrder: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/prodorder`,
      data,
    });
  },

  getResources: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/resources`,
    });
  },

  updateProductionOrder: (data, docEntry) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/prodorder/${docEntry}`,
      data,
    });
  },

  updateProductionOrderBatch: (data) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/prodorderbatch`,
      data,
    });
  },

  getProductionOrderById: (id) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/prodorder/${id}`,
    });
  },

  getActiveTimeByRange: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/activeTimes`,
      data,
    });
  },

  getSapProdOperationForDependency: (filter) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getSapProdOperationForDependency/${filter}`,
    });
  },

  getSapDependencyList: (DocEntry, LineNum) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getSapDependencyList/${DocEntry}/${LineNum}`,
    });
  },

  createSapProductionDependency: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/createSapProductionDependency`,
      data,
    });
  },

  updateSapProductionDependency: (data) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/updateSapProductionDependency`,
      data,
    });
  },

  deleteSapProductionDependency: (data) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/deleteSapProductionDependency`,
      data,
    });
  },
};
export default sapResourcePlannerService;
