import { Field } from 'formik';

import { FormErrorUI, FormLabelUI, DatePickerUI, AutoCompleteSelectUI } from '../Interface';

const PlanAbsenceFields = ({
  values,
  errors,
  setFieldValue,
  validateField,
  disabled,
  selectedValues,
  autocompleteOptions,
}) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4 md:col-span-2">
        <Field
          type="date"
          name="startDate"
          component={DatePickerUI}
          value={values.startDate}
          fullWidth
          label={<FormLabelUI text="Kezdő dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.startDate} />}
          onChange={(date) => {
            setFieldValue('startDate', date).then(() => {
              validateField('startDate');
            });
          }}
          disabled={disabled}
          InputLabelProps={{ shrink: values.startDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="date"
          name="endDate"
          component={DatePickerUI}
          value={values.endDate}
          fullWidth
          label={<FormLabelUI text="Befejező dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.endDate} />}
          onChange={(date) => {
            setFieldValue('endDate', date).then(() => {
              validateField('endDate');
            });
          }}
          disabled={disabled}
          InputLabelProps={{ shrink: values.endDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="resourceOneId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Dolgozó" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.resourceOneId} />}
          onChange={(_e, newVal) => {
            setFieldValue('resourceOneId', newVal?.value ?? null).then(() => {
              validateField('resourceOneId');
            });
          }}
          disabled={disabled}
          InputLabelProps={{ shrink: values.resourceOneId !== '' }}
          selectedValue={values.resourceOneId}
          selectedLabelValue={selectedValues?.resourceOne?.name}
          dataset={autocompleteOptions.resourceOne}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="typeId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Típus" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.typeId} />}
          onChange={(_e, newVal) => {
            setFieldValue('typeId', newVal?.value ?? null).then(() => {
              validateField('typeId');
            });
          }}
          disabled={disabled}
          InputLabelProps={{ shrink: values.typeId !== '' }}
          selectedValue={values.typeId}
          selectedLabelValue={selectedValues?.type?.itemName}
          dataset={autocompleteOptions.type}
        />
      </div>
    </div>
  );
};

export default PlanAbsenceFields;
