import React from 'react';
import { Box, Chip, IconButton, Typography } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ColorPicker } from '../../config/ColorPicker';

import { ButtonUI, LinearStaticProgressUI } from '../Interface';
import Fields from './Fields';

const JobMonitorCard = ({ data, handleNavigate, getProductionById }) => {
  const bgColor = ColorPicker.find((color) => color.colorName === data.production.status.color);

  const { totalReportedQty, totalReportedTimeSpent } = data.productionReport.reduce(
    (acc, report) => {
      if (report.prodOperationId === data.id) {
        acc.totalReportedQty += Number(report.quantity);
        acc.totalReportedTimeSpent += Number(report.timeSpent);
      }
      return acc;
    },
    { totalReportedQty: 0, totalReportedTimeSpent: 0 }
  );

  const productionQuantity = Number(data.production.productionQuantity);
  const preparedness = productionQuantity > 0 ? Math.round((totalReportedQty / productionQuantity) * 100) : 0;

  return (
    <Box
      className="min-h-[200px] my-5 rounded-md p-4 shadow-md"
      sx={{ backgroundColor: bgColor?.jobMonitorBgColor, '&:hover': { background: bgColor?.jobMonitorHoverColor } }}
    >
      <div className="flex justify-between w-full flex-wrap">
        <div className="flex items-center gap-3 flex-wrap">
          <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{data.label}</Typography>
          <Chip
            label={data.production.status.itemName}
            size="small"
            sx={{ backgroundColor: bgColor.jobMonitorChipColor }}
          />
          <Typography>{data.production.articleName}</Typography>
        </div>
        <div className="w-[400px] my-2">
          <LinearStaticProgressUI bgColor={bgColor.jobMonitorChipColor} value={preparedness} />
        </div>
      </div>
      <div className="text-end">
        <ButtonUI
          text="Lejelentés"
          className="bg-white text-black mr-3 py-2.5"
          onClick={() => handleNavigate(data.id)}
          size="sm"
        />
        <IconButton sx={{ padding: 0, margin: 0 }} onClick={() => getProductionById(data.productionId)}>
          <div className="bg-white text-black px-2 rounded">
            <RemoveRedEyeIcon sx={{ fontSize: '1.4rem' }} />
          </div>
        </IconButton>
      </div>
      <Fields
        data={data}
        bgColor={bgColor}
        totalReportedQty={totalReportedQty}
        totalReportedTimeSpent={totalReportedTimeSpent}
      />
    </Box>
  );
};

export default JobMonitorCard;
