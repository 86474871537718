import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import { FormControlLabel } from '@mui/material';

const SwitchUI = ({ disabled, onClick, itemId, checked: initialChecked, label }) => {
  const [checked, setChecked] = useState(initialChecked);

  const handleChange = async (event) => {
    const newChecked = event.target.checked;
    setChecked(newChecked);
    if (onClick) {
      onClick(itemId, newChecked);
    }
  };

  return (
    <FormControlLabel
      control={<Switch checked={checked} onChange={handleChange} disabled={disabled} />}
      label={label}
    />
  );
};

export default SwitchUI;
