import React, { useEffect, useState } from 'react';
import useParam from '../../context/ParamContext';

const ChipContent = ({ eventInfo }) => {
  const { params } = useParam();
  const [chipObj, setChipObj] = useState(null);

  useEffect(() => {
    let paramKey = 'TASKLIST_RESOURCE_PLANNER_CHIP_CONTENT';

    if (params[paramKey]) {
      const parsedObj = JSON.parse(params[paramKey]);
      setChipObj(parsedObj);
    }
  }, [params]);

  const task = eventInfo.event._def.extendedProps.eventData;

  const setChipContent = () => {
    if (!chipObj || !task) {
      return null;
    }

    return (
      // @TODO style-t basszuk ki sos munka vót
      <span className="truncate block" style={{ marginTop: '2px', marginBottom: '2px' }}>
        {chipObj.map((item, index) => {
          const { label } = item;
          let taskValue;

          if (label.includes('.')) {
            const [parentField, childField] = label.split('.');
            if (Array.isArray(task[parentField])) {
              taskValue = task[parentField].map((elem) => elem[childField]).join(', ');
            } else {
              taskValue = task[parentField] ? task[parentField][childField] : null;
            }
          } else {
            taskValue = task[label];
            if (label === 'preparedness') {
              taskValue = `${taskValue ?? 0} %`;
            }
          }

          return (
            <span key={index}>
              {index > 0 && ' - '}
              {taskValue}
            </span>
          );
        })}
      </span>
    );
  };

  return <div>{setChipContent()}</div>;
};

export default ChipContent;
