import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'planAbsence';

const planAbsenceService = {
  getPlanAbsences: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/planAbsences`,
      data,
    });
  },

  createPlanAbsence: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updatePlanAbsence: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deletePlanAbsence: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },
};

export default planAbsenceService;
