import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { FormHelperText } from '@mui/material';

const RadioGroupUI = ({ label, option, helperText, value, disabled, ...props }) => {
  return (
    <>
      <FormControl sx={{ marginTop: 1 }} disabled={disabled}>
        {label ? <FormLabel>{label}</FormLabel> : null}
        <RadioGroup value={value} {...props}>
          {option &&
            option.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                checked={value === item.value}
                control={<Radio />}
                label={item.label}
              />
            ))}
        </RadioGroup>
      </FormControl>
      <FormHelperText>{helperText}</FormHelperText>
    </>
  );
};

export default RadioGroupUI;
