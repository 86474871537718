import React from 'react';
import { Field } from 'formik';

import { InputUI, AutoCompleteSelectUI, FormErrorUI, FormLabelUI } from '../Interface';

export const ItemFields = ({
  viewField,
  selectedValues,
  values,
  validateField,
  setFieldValue,
  errors,
  autocompleteOptions,
  addField,
}) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5">
      <div className="col-span-4">
        <Field
          type="text"
          name="itemName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Cikknév" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.itemName} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.itemName && values.itemName !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="itemCode"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Cikkszám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.itemCode} />}
          disabled={viewField || addField}
          InputLabelProps={{ shrink: values.itemCode && values.itemCode !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="quantityUnitId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Mennyiségi egység" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.quantityUnitId} />}
          onChange={(_e, newVal) => {
            setFieldValue('quantityUnitId', newVal?.value ?? null).then(() => {
              validateField('quantityUnitId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.quantityUnitId !== '' }}
          selectedValue={values.quantityUnitId}
          selectedLabelValue={selectedValues?.quantityUnit?.name}
          dataset={autocompleteOptions?.quantityUnit}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="vatId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Áfa" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.vatId} />}
          onChange={(_e, newVal) => {
            setFieldValue('vatId', newVal?.value ?? null).then(() => {
              validateField('vatId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.vatId !== '' }}
          selectedValue={values.vatId}
          selectedLabelValue={selectedValues?.vat?.name}
          dataset={autocompleteOptions?.vat}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="groupId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Cikk csoport" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.groupId} />}
          onChange={(_e, newVal) => {
            setFieldValue('groupId', newVal?.value ?? null).then(() => {
              validateField('groupId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.groupId !== '' }}
          selectedValue={values.groupId}
          selectedLabelValue={selectedValues?.itemGroup?.itemName}
          dataset={autocompleteOptions?.itemGroup}
        />
      </div>
    </div>
  );
};
