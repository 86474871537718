import React, { useState, useEffect } from 'react';

import { dataGridPremiumColumns } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import currencyService from '../../service/currency.service';

export const CurrencyReports = ({ refetch, values, viewField, operatorsDisabled, rowSelection }) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'code', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    currencyService.getItems(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = dataGridPremiumColumns([
    {
      field: 'code',
      headerName: 'Pénznem kódja',
      type: 'string',
    },
    {
      field: 'isoCode',
      headerName: 'Pénznem ISO kódja',
      type: 'string',
    },
    {
      field: 'name',
      headerName: 'Pénznem megnevezése',
      type: 'string',
    },
    {
      field: 'priceRounding',
      headerName: 'Kerekítés - ár',
      type: 'string',
    },
    {
      field: 'amountRounding',
      headerName: 'Kerekítés - összeg',
      type: 'string',
    },
    {
      field: 'amountDifference',
      headerName: 'Fizetési összeg eltérés',
      type: 'string',
    },
    {
      field: 'active',
      headerName: 'Aktív',
      type: 'boolean',
    },
  ]);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.currencyList}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      refetch={{ refetchTable, setRefetchTable }}
      rowCount={reports.rowCount}
      columns={[...columns]}
      onRowClick={(rowData) => {
        if (!viewField) return;
        setSelectedValues(rowData.row);
      }}
      getRowId={(row) => row.code}
      paginationAndfilterMode="server"
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      disableRowSelectionOnClick={!viewField}
    />
  );
};

export default CurrencyReports;
