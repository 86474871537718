import React from 'react';
import { Field } from 'formik';

import { FormErrorUI, FormLabelUI, InputUI } from '../Interface';

const VatFields = ({ viewField, values, errors }) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="vatName"
          as={InputUI}
          disabled={viewField}
          fullWidth
          label={<FormLabelUI text="Áfa rövid neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.vatName} />}
          InputLabelProps={{ shrink: values.vatName !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="number"
          name="vat"
          as={InputUI}
          disabled={viewField}
          fullWidth
          label={<FormLabelUI text="Áfa" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.vat} />}
          InputLabelProps={{ shrink: values.vat !== '' }}
        />
      </div>
    </div>
  );
};

export default VatFields;
