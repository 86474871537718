import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import dayjs from 'dayjs';
import Yup from '../../../utils/yup';
import utc from 'dayjs/plugin/utc';
import { divide, multiply } from 'mathjs';
import { v4 as uuidv4 } from 'uuid';

import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';
import productionService from '../../../service/production.service';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI, ConfirmDialogUI, ButtonUI } from '../../Interface';
import ProductionFields from './ProductionFields';
import ProductionTabs from './ProductionTabs';
import sourcelistService from '../../../service/sourcelist.service';

export const ProductionPage = ({ productionState, dialog }) => {
  const {
    selectedValues,
    setSelectedValues,
    viewField,
    setViewField,
    operatorsDisabled,
    setOperatorsDisabled,
    autocompleteOptions,
    setRefetchTable,
    setRowSelectionModel,
    user,
    canEdit,
    submenu,
    autocompleteData,
  } = productionState;

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [initValues, setInitValues] = useState({});

  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});

  const [sourceData, setSourceData] = useState({});

  const defaultOperation = {
    id: null,
    isNew: true,
    operationId: null,
    resourceOneId: null,
    startDate: dayjs(new Date()),
    timeSpent: 1,
    description: '',
    createdBy: user.userId,
    label: '',
    fromSourceData: false,
    productionReport: [],
  };

  const defaultItem = {
    id: null,
    isNew: true,
    itemCode: null,
    quantity: 1,
    createdBy: user.userId,
    fromSourceData: false,
  };

  const def = {
    workingNumber: '',
    customerOrdNumber: '',
    projectId: null,
    articleNumber: '',
    articleName: '',
    productionQuantity: 1,
    me: null,
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()).add(1, 'hour'),
    statusId: null,
    priorityId: null,
    companyId: null,
    description: '',
    productionOperation: [],
    productionItem: [],
    userId: null,
  };

  useEffect(() => {
    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          statusId: autocompleteData?.status?.defaultValue ?? null,
          priorityId: autocompleteData?.priority?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          status: autocompleteData?.status?.defItem,
          priority: autocompleteData?.priority?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    if (user) {
      setDefaultValues((prev) => {
        return { ...prev, userId: user?.userId };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          user: user,
        };
      });
    }
  }, [user]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const validationSchema = Yup.object().shape({
    workingNumber: Yup.string().nullable(),
    customerOrdNumber: Yup.string(),
    projectId: Yup.string().nullable(),
    articleNumber: Yup.string().required(),
    articleName: Yup.string().required(),
    productionQuantity: Yup.number().nullable().positive(),
    me: Yup.string().nullable().required(),
    startDate: Yup.string(),
    endDate: Yup.string(),
    statusId: Yup.string().nullable(),
    priorityId: Yup.string().nullable(),
    companyId: Yup.string().nullable(),
    description: Yup.string(),
    productionOperation: Yup.array().of(
      Yup.object().shape({
        operationId: Yup.string().required(),
        resourceOneId: Yup.string().required(),
        startDate: Yup.string().required(),
        timeSpent: Yup.number().required().positive(),
        description: Yup.string().nullable(),
      })
    ),
    productionItem: Yup.array().of(
      Yup.object().shape({
        itemCode: Yup.string().required(),
        quantity: Yup.number().required().positive(),
      })
    ),
  });

  const valuesToForm = () => {
    const {
      workingNumber,
      customerOrdNumber,
      projectId,
      articleNumber,
      articleName,
      productionQuantity,
      me,
      startDate,
      endDate,
      statusId,
      priorityId,
      userId,
      companyId,
      description,
      productionOperation,
      productionItem,
    } = selectedValues;

    setInitValues({
      workingNumber,
      customerOrdNumber,
      projectId,
      articleNumber,
      articleName,
      productionQuantity,
      me,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      statusId,
      priorityId,
      userId,
      companyId,
      description,
      productionOperation:
        productionOperation &&
        productionOperation.map((po) => ({
          id: po.id,
          operationId: po.operationId,
          resourceOneId: po.resourceOneId,
          startDate: po.startDate,
          timeSpent: po.timeSpent,
          description: po.description,
          createdBy: po.user?.userId,
          label: po.label,
          fromSourceData: po.fromSourceData,
          productionReport: po.productionReport,
        })),
      productionItem:
        productionItem &&
        productionItem.map((po) => ({
          id: po.id,
          itemCode: po.itemCode,
          quantity: po.quantity,
          fromSourceData: po.fromSourceData,
        })),
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField || !dialog.isUpdate) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    productionService.updateProduction(updData, selectedValues.id).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      if (dialog) {
        dialog.setProductionChanged(true);
        dialog.handleClose();
      }
      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    productionService
      .createProduction(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRowSelectionModel(data.id);
        setSelectedValues(data);
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);

        if (dialog) {
          dialog.setProductionChanged(true);
          dialog.handleClose();
          setSelectedValues(defaultSelectedValues);
        }
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    productionService.deleteProduction(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      if (dialog) {
        dialog.setProductionChanged(true);
        dialog.handleClose();
      }
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);
    });
  };

  const getSourceData = async (itemCode) => {
    if (!itemCode) {
      setSourceData({});
      return;
    }

    const sd = await sourcelistService.getSourcelistByItemCode(itemCode);
    setSourceData(sd);
    if (
      (Object.keys(sd).length > 0 && !viewField) ||
      (Object.keys(sd).length > 0 && dialog && dialog?.isUpdate && !viewField) ||
      (Object.keys(sd).length > 0 && dialog && !dialog?.isUpdate && !dialog.isView)
    ) {
      setShowConfirmDialog(true);
    }
  };

  const addSourceLine = (setFieldValue, values) => {
    for (const op of sourceData.sourceListOperation) {
      if (op.sourceListOperationMachine.length === 0) {
        const findOperation = autocompleteOptions.operation.find((f) => f.value === op.operationId);
        addNotification({
          content: `Nincs megadva a művelethez tartozó erőforrás! (${findOperation.label})`,
          type: NotificationType.ERROR,
        });
        return;
      }
    }

    const newOperationLines = sourceData.sourceListOperation.map((mp) => {
      /*const result = multiply(
        divide(mp.sourceListOperationMachine[0].neededQuantity ?? 1, sourceData.baseQuantity ?? 1),
        values.productionQuantity ?? 1
      );*/
      return {
        id: uuidv4(),
        isNew: true,
        operationId: mp.operationId,
        resourceOneId: mp.sourceListOperationMachine[0].resourceId,
        startDate: dayjs(new Date()),
        timeSpent: mp.sourceListOperationMachine[0].neededQuantity,
        description: '',
        createdBy: user.userId,
        label: '',
        fromSourceData: true,
      };
    });

    setFieldValue('productionOperation', newOperationLines);

    const newItemLines = sourceData.sourceListItem.map((mp) => {
      return {
        id: uuidv4(),
        isNew: true,
        itemCode: mp.itemCode,
        quantity: multiply(mp.neededQuantity, values.productionQuantity ?? 1),
        fromSourceData: true,
      };
    });

    setFieldValue('productionItem', newItemLines);

    setShowConfirmDialog(false);
  };

  const onAddClick = () => {
    setSelectedValues(defaultSelectedValues);
    setInitValues(defaultValues);
    setViewField(false);
    setAddField(false);
  };

  const onModifyClick = () => {
    setViewField(false);
    setEditButtonvisible(false);
  };

  const onDeleteClick = () => {
    setShowDeleteDialog(true);
  };

  const onCopyClick = () => {
    setViewField(false);
    setAddField(false);
  };

  return (
    <>
      <>
        <div className="h-full overflow-auto custom-scrollbar">
          <div className="flex items-center flex-wrap">
            <FormHeaderUI
              addClick={!dialog ? onAddClick : null}
              addDisabled={!viewField || !canEdit}
              modClick={!dialog || dialog.isUpdate ? onModifyClick : null}
              modDisabled={!viewField || operatorsDisabled || !canEdit}
              deleteClick={!dialog || dialog.isUpdate ? onDeleteClick : null}
              deleteDisabled={!viewField || operatorsDisabled || !canEdit}
              copyClick={!dialog || dialog.isUpdate ? onCopyClick : null}
              copyDisabled={!viewField || operatorsDisabled || !canEdit}
            />
            {!dialog && (
              <ButtonUI
                text="Nyomtatás"
                size="sm"
                className="bg-labelColor py-3"
                onClick={() =>
                  addNotification({
                    content: 'Nyomtatás elindult!',
                    type: NotificationType.INFO,
                  })
                }
                disabled={!viewField || operatorsDisabled}
              />
            )}
          </div>

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue, errors, validateField, resetForm }) => {
              const calc = async (ind, val) => {
                const findedOp = sourceData.sourceListOperation.find((so) => so.operationId === val?.operationId);
                const findedRes = findedOp?.sourceListOperationMachine.find(
                  (om) => om.resourceId === val.resourceOneId
                );

                const result = multiply(
                  divide(findedRes?.neededQuantity ?? 1, sourceData.baseQuantity ?? 1),
                  values.productionQuantity ?? 1
                );

                await setFieldValue(`productionOperation.${ind}.timeSpent`, result);
              };

              const calcItem = async (ind, val) => {
                const findedItem = sourceData.sourceListItem.find((so) => so.itemCode === val?.itemCode);

                const result = multiply(
                  divide(findedItem?.quantity ?? 1, sourceData.baseQuantity ?? 1),
                  values.productionQuantity ?? 1
                );

                await setFieldValue(`productionItem.${ind}.quantity`, result);
              };

              const calculateLineQty = async (index, isItemLine = false) => {
                if (Object.keys(sourceData).length > 0) {
                  // ha művelet sor módosult
                  if (index && !isItemLine) {
                    if (values.productionOperation[index].fromSourceData) {
                      return calc(index, values.productionOperation[index]);
                    }
                  }
                  // ha cikk sor módosult
                  if (index && isItemLine) {
                    if (values.productionItem[index].fromSourceData) {
                      return calcItem(index, values.productionItem[index]);
                    }
                  }

                  // ha a productQuantity módosult
                  for (const [i, val] of values.productionOperation.entries()) {
                    if (val.fromSourceData) {
                      await calc(i, val);
                    }
                  }

                  for (const [i, val] of values.productionItem.entries()) {
                    if (val.fromSourceData) {
                      await calcItem(i, val);
                    }
                  }
                }
              };

              return (
                <Form>
                  <ProductionFields
                    viewField={(!dialog || dialog?.isUpdate || dialog.isView) && viewField}
                    submenuId={submenu}
                    selectedValues={selectedValues}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    validateField={validateField}
                    autocompleteOptions={autocompleteOptions}
                    autocompleteData={autocompleteData}
                    getSourceData={getSourceData}
                    calculateLineQty={calculateLineQty}
                  />
                  <ProductionTabs
                    disabled={(!dialog || dialog?.isUpdate || dialog.isView) && viewField}
                    submenuId={submenu}
                    setFieldValue={setFieldValue}
                    selectedValues={selectedValues}
                    setDefaultValues={setDefaultValues}
                    user={user}
                    setRefetchTable={setRefetchTable}
                    defaultField={defaultOperation}
                    defaultItem={defaultItem}
                    values={values}
                    errors={errors}
                    autocompleteOptions={autocompleteOptions}
                    autocompleteData={autocompleteData}
                    sourceData={sourceData}
                    calculateLineQty={calculateLineQty}
                  />
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                      if (dialog) {
                        dialog.handleClose();
                      }
                    }}
                    disabled={
                      dialog
                        ? dialog.isUpdate && ((editButtonVisible && addField) || (!editButtonVisible && !addField))
                        : (editButtonVisible && addField) || (!editButtonVisible && !addField)
                    }
                  />
                  <ConfirmDialogUI
                    handleChangeElement={() => {
                      addSourceLine(setFieldValue, values);
                    }}
                    show={showConfirmDialog}
                    onHide={() => {
                      setShowConfirmDialog(false);
                      setSourceData({});
                    }}
                    message={'A válaszott cikkhez tartozik forrásjegyzék. Importálja a forrásjegyzéket?'}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </>

      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
