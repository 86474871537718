import React, { useEffect } from 'react';
import { Field } from 'formik';

import { AutoCompleteSelectUI, DatePickerUI, FormErrorUI, FormLabelUI, InputUI } from '../Interface';

const DocumentFields = ({
  viewField,
  values,
  validateField,
  setFieldValue,
  errors,
  autocompleteOptions,
  selectedValues,
  autocompleteData,
}) => {
  const updateFullAddress = () => {
    if (values) {
      const fullAddress = `${values.postCode}, ${values.city}, ${values.street}`;
      setFieldValue('fullAddress', fullAddress);
    }
  };

  useEffect(() => {
    if (values?.companyId) {
      updateFullAddress();
    }
  }, [values?.companyId, setFieldValue]);

  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="docNum"
          as={InputUI}
          fullWidth
          disabled
          label={<FormLabelUI text="Bizonylatszám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.docNum} />}
          InputLabelProps={{ shrink: values.docNum !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="companyId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Cég" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.companyId} />}
          onChange={(_e, newVal) => {
            setFieldValue('companyId', newVal?.value ?? null).then(() => {
              validateField('companyId');
            });

            const selectedCompany = autocompleteData.company.find((i) => i.companyId === newVal.value);
            if (selectedCompany) {
              setFieldValue('postCode', selectedCompany.postCode);
              setFieldValue('city', selectedCompany.city);
              setFieldValue('street', selectedCompany.street);
            }
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.companyId !== '' }}
          selectedValue={values.companyId}
          selectedLabelValue={selectedValues?.company?.companyName}
          dataset={autocompleteOptions?.company}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="fullAddress"
          as={InputUI}
          disabled
          fullWidth
          label={<FormLabelUI text="Cím" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.fullAddress} />}
          InputLabelProps={{ shrink: values.fullAddress !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="date"
          name="docDate"
          component={DatePickerUI}
          value={values.docDate}
          fullWidth
          label={<FormLabelUI text="Kiállítás dátuma" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.docDate} />}
          onChange={(date) => {
            setFieldValue('docDate', date).then(() => {
              validateField('docDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.docDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="date"
          name="completionDate"
          component={DatePickerUI}
          value={values.completionDate}
          fullWidth
          label={<FormLabelUI text="Teljesítés dátuma" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.completionDate} />}
          onChange={(date) => {
            setFieldValue('completionDate', date).then(() => {
              validateField('completionDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.completionDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="paymentMethod"
          as={InputUI}
          disabled={viewField}
          fullWidth
          label={<FormLabelUI text="Fizetési mód" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.paymentMethod} />}
          InputLabelProps={{ shrink: values.paymentMethod !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="date"
          name="docDate"
          component={DatePickerUI}
          value={values.paymentDeadline}
          fullWidth
          label={<FormLabelUI text="Fizetési határidő" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.paymentDeadline} />}
          onChange={(date) => {
            setFieldValue('paymentDeadline', date).then(() => {
              validateField('paymentDeadline');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.paymentDeadline !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="orderNumber"
          as={InputUI}
          disabled={viewField}
          fullWidth
          label={<FormLabelUI text="Rendelésszám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.orderNumber} />}
          InputLabelProps={{ shrink: values.orderNumber !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="projectId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Projekt" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.projectId} />}
          onChange={(_e, newVal) => {
            setFieldValue('projectId', newVal?.value ?? null).then(() => {
              validateField('projectId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.projectId !== '' }}
          selectedValue={values.projectId}
          selectedLabelValue={selectedValues?.project?.projectName}
          dataset={autocompleteOptions?.project}
        />
      </div>
    </div>
  );
};

export default DocumentFields;
