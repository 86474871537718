import { useState } from 'react';
import { GridRowModes, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';

import { dataGridColumns } from '../../../utils/helper';

import {
  DataGridUI,
  DataGridIdentifiers,
  FormErrorUI,
  AddButtonUi,
  InputUI,
  OrderArrowsUI,
  ButtonUI,
  DatePickerUI,
} from '../../Interface';
import TemplateImportDialog from './TemplateImport/TemplateImportDialog';

function EditToolbar(props) {
  const { setRowModesModel, disabled, push, defaultTaskList, setIsImportDialogOpen } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    const newVisOrder = (values.taskList?.length || 0) + 1;

    defaultTaskList.id = uuidv4();
    defaultTaskList.visOrder = newVisOrder;
    await push(defaultTaskList);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultTaskList.id]: { mode: GridRowModes.Edit },
    }));

    const fieldsToValidate = [
      `taskList.${values.taskList.length ?? 0}.name`,
      `taskList.${values.taskList.length ?? 0}.duration`,
      `taskList.${values.taskList.length ?? 0}.startDate`,
      `taskList.${values.taskList.length ?? 0}.preparedness`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
    }
  };

  return (
    <GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
      <ButtonUI
        disabled={disabled}
        text="Template importálás"
        size="sm"
        color="blue-gray"
        variant="gradient"
        className="mb-1.5"
        onClick={() => setIsImportDialogOpen(true)}
      />
    </GridToolbarContainer>
  );
}

const ProjectTaskList = ({ disabled, defaultTaskList, autocompleteData, autoCompleteOptions, selectedValues }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);

  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.taskList.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    const fieldsToValidate = [
      `taskList.${valueIndex}.name`,
      `taskList.${valueIndex}.duration`,
      `taskList.${valueIndex}.startDate`,
      `taskList.${valueIndex}.preparedness`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
      if (errors?.taskList?.[valueIndex]?.[field.split('.').pop()]) {
        return;
      }
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleAddItemFromTemplate = (item) => {
    const newVisOrder = (values.taskList?.length || 0) + 1;

    const updatedImportedItems = item.map((item, index) => ({
      ...item,
      visOrder: newVisOrder + index,
      duration: item.duration ?? 1,
      startDate: selectedValues.projectStartDate,
    }));

    setFieldValue('taskList', [...values.taskList, ...updatedImportedItems]);
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleOrderChange = (id, currentOrder, direction) => {
    const newOrder = direction === 'up' ? currentOrder - 1 : currentOrder + 1;

    if (newOrder < 1 || newOrder > values.taskList.length) {
      return;
    }

    const updatedList = [...values.taskList]
      .map((item) => {
        if (item.id === id) {
          return { ...item, visOrder: newOrder };
        } else if (item.visOrder === newOrder) {
          return { ...item, visOrder: currentOrder };
        }
        return item;
      })
      .sort((a, b) => a.visOrder - b.visOrder);

    setFieldValue('taskList', updatedList);
  };

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridColumns([
      {
        field: 'name',
        headerName: 'Feladatok',
        flex: 1,
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`taskList.${valueIndex}.name`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.taskList?.[valueIndex]?.name} />}
              onChange={(e) => {
                setFieldValue(`taskList.${valueIndex}.name`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`taskList.${valueIndex}.name`)}
            />
          );
        },
      },
      {
        field: 'duration',
        headerName: 'Időintervallum (nap)',
        flex: 0.5,
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`taskList.${valueIndex}.duration`}
              type="number"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.taskList?.[valueIndex]?.duration} />}
              onChange={(e) => {
                setFieldValue(`taskList.${valueIndex}.duration`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`taskList.${valueIndex}.duration`)}
            />
          );
        },
      },
      {
        field: 'startDate',
        headerName: 'Kezdő dátum',
        flex: 0.5,
        editable: true,
        sortable: false,
        renderCell: (data) => {
          return dayjs(data.row.startDate).format('YYYY.MM.DD');
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`taskList.${valueIndex}.startDate`}
              type="number"
              variant="standard"
              as={DatePickerUI}
              fullWidth
              value={dayjs(data.row.startDate)}
              helperText={<FormErrorUI message={errors?.taskList?.[valueIndex]?.startDate} />}
              onChange={(newVal) => {
                setFieldValue(`taskList.${valueIndex}.startDate`, newVal ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal ?? null,
                });
              }}
              onBlur={async () => await validateField(`taskList.${valueIndex}.startDate`)}
            />
          );
        },
      },
      {
        field: 'preparedness',
        headerName: 'Készültség',
        flex: 0.5,
        editable: true,
        sortable: false,
        valueGetter: (params) => {
          return `${params.row.preparedness ?? 0} %`;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`taskList.${valueIndex}.preparedness`}
              type="number"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.taskList?.[valueIndex]?.preparedness} />}
              onChange={(e) => {
                setFieldValue(`taskList.${valueIndex}.preparedness`, parseInt(e.target.value));
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: parseInt(e.target.value),
                });
              }}
              onBlur={async () => await validateField(`taskList.${valueIndex}.preparedness`)}
            />
          );
        },
      },
      {
        field: 'visOrder',
        flex: 0.2,
        headerName: 'Sorrend',
        renderCell: (params) => {
          const handleUpClick = () => handleOrderChange(params.row.id, params.row.visOrder, 'up');
          const handleDownClick = () => handleOrderChange(params.row.id, params.row.visOrder, 'down');
          return <OrderArrowsUI onUpClick={handleUpClick} onDownClick={handleDownClick} disabled={disabled} />;
        },
        sortable: false,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        flex: 0.25,
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  const valueIndex = getValueIndex(values, id);
                  return remove(valueIndex);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
    ]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <FieldArray name="taskList">
            {({ remove, push }) => (
              <DataGridUI
                datagridIdentifier={DataGridIdentifiers.projectTaskList}
                sx={{ height: 400 }}
                columns={columns(setFieldValue, validateField, errors, remove)}
                rows={values?.taskList ?? []}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    disabled,
                    push,
                    defaultTaskList,
                    setRowModesModel,
                    setIsImportDialogOpen,
                  },
                }}
                onCellDoubleClick={(_params, event) => {
                  event.defaultMuiPrevented = true;
                }}
              />
            )}
          </FieldArray>
        </div>
      </div>
      <TemplateImportDialog
        open={isImportDialogOpen}
        handleClose={() => setIsImportDialogOpen(false)}
        autocompleteData={autocompleteData}
        autoCompleteOptions={autoCompleteOptions}
        onItemAdd={handleAddItemFromTemplate}
      />
    </div>
  );
};

export default ProjectTaskList;
