import React, { useEffect, useState } from 'react';
import { Field } from 'formik';
import { useFormikContext } from 'formik';

import {
  InputUI,
  AutoCompleteSelectUI,
  FormErrorUI,
  FormLabelUI,
  RadioGroupUI,
  CheckboxGroupSingleSelectUI,
  DatePickerUI,
} from '../Interface';
import {
  LeaderReportActivityList,
  LeaderReportShiftList,
  LeaderReportTypeList,
} from '../../config/sapShiftLeaderReport';
import sapShiftLeaderReportService from '../../service/sapShiftLeaderReport.service';

const SapShiftLeaderReportFields = ({ viewField, selectedValues, autocompleteOptions, autocompleteData }) => {
  const { values, validateField, setFieldValue, errors, validateForm } = useFormikContext();

  const [isActivity, setIsActivity] = useState(false);
  const [filteredOps, setFilteredOps] = useState([]);
  const [resWithMachines, setResWithMachines] = useState({});

  useEffect(() => {
    if (values?.activity) {
      setFieldValue('workHours', 8);
      setFieldValue('project', null);
      setFieldValue('operation', null);
      setFieldValue('machine', null);
      validateForm();
      setIsActivity(true);
    } else {
      setIsActivity(false);
      //setFieldValue('workHours', '');
    }
  }, [values.activity]);

  const getProjectById = async () => {
    if (values.project) {
      const project = await sapShiftLeaderReportService.getProjectById(values.project);

      const resourceWithMachines = project.reduce((acc, current) => {
        const { ItemCode, U_QNT_GEP, U_QNT_MEGJ } = current;
        if (!acc[ItemCode]) {
          acc[ItemCode] = { machines: [], comments: [] };
        }

        if (!acc[ItemCode].machines.includes(U_QNT_GEP)) {
          acc[ItemCode].machines.push(U_QNT_GEP);
        }
        if (!acc[ItemCode].machines.includes(U_QNT_MEGJ) && U_QNT_MEGJ !== null) {
          acc[ItemCode].comments.push(U_QNT_MEGJ);
        }
        return acc;
      }, {});

      const fOps = autocompleteOptions.operation.filter((op) => resourceWithMachines.hasOwnProperty(op.value));
      setResWithMachines(resourceWithMachines);
      setFilteredOps(fOps);
    }
  };

  useEffect(() => {
    getProjectById();
    if (!viewField) {
      setFieldValue('operation', null);
      setFieldValue('machine', null);
    }
  }, [values.project]);

  useEffect(() => {
    if (!viewField) {
      setFieldValue('machine', null);
    }
  }, [values.operation]);

  const operationNoteSet = [
    ...new Map(
      [
        ...(resWithMachines?.[values.operation]?.comments || []).map((c) => ({ label: c, value: c })),
        ...(selectedValues?.operationNote
          ? [{ label: selectedValues?.operationNote, value: selectedValues?.operationNote }]
          : []),
      ].map((item) => [item.label, item])
    ).values(),
  ];

  return (
    <div className="grid grid-cols-6 gap-x-4 mx-4 mb-5">
      <div className="col-span-2 ">
        <Field
          type="date"
          name="date"
          component={DatePickerUI}
          value={values.date}
          fullWidth
          label={<FormLabelUI text="Dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.date} />}
          onChange={(date) => {
            setFieldValue('date', date).then(() => {
              validateField('date');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.date !== '' }}
        />
      </div>
      <div className="col-span-2 ">
        <Field
          type="autocomplete"
          name="project"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Projekt" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.project} />}
          onChange={(_e, newVal) => {
            setFieldValue('project', newVal?.value ?? null).then(() => {
              validateField('project');
            });
          }}
          disabled={viewField || isActivity}
          InputLabelProps={{ shrink: values.project !== '' }}
          selectedValue={values.project}
          selectedLabelValue={selectedValues?.projectData?.Project}
          dataset={autocompleteOptions?.project}
        />
      </div>
      <div className="col-span-2 ">
        <Field
          type="autocomplete"
          name="workerId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Dolgozó" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.workerId} />}
          onChange={(_e, newVal) => {
            setFieldValue('workerId', newVal?.value ?? null).then(() => {
              validateField('workerId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.workerId !== '' }}
          selectedValue={values.workerId}
          selectedLabelValue={selectedValues?.worker?.FullName}
          dataset={autocompleteOptions?.employee}
        />
      </div>
      <div className="col-span-2 ">
        <Field
          type="autocomplete"
          name="operation"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Művelet" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.operation} />}
          onChange={(_e, newVal) => {
            setFieldValue('operation', newVal?.value ?? null).then(() => {
              validateField('operation');
            });
          }}
          disabled={viewField || isActivity}
          InputLabelProps={{ shrink: values.operation !== '' }}
          selectedValue={values.operation}
          //selectedLabelValue={selectedValues?.operation}
          dataset={values.project && !viewField ? filteredOps : autocompleteOptions?.operation}
        />
      </div>
      <div className="col-span-2 ">
        <Field
          type="autocomplete"
          name="operationNote"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Művelet megjegyzés" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.operationNote} />}
          onChange={(_e, newVal) => {
            setFieldValue('operationNote', newVal?.value ?? null).then(() => {
              validateField('operationNote');
            });
          }}
          disabled={viewField || isActivity}
          InputLabelProps={{ shrink: values.operationNote !== '' }}
          selectedValue={values.operationNote}
          selectedLabelValue={selectedValues?.operationNote}
          dataset={operationNoteSet}
        />
      </div>
      <div className="col-span-2 ">
        <Field
          type="autocomplete"
          name="machine"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Gép" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.machine} />}
          onChange={(_e, newVal) => {
            setFieldValue('machine', newVal?.value ?? null).then(() => {
              validateField('machine');
            });
          }}
          disabled={viewField || isActivity}
          InputLabelProps={{ shrink: values.machine !== '' }}
          selectedValue={values.machine}
          //selectedLabelValue={selectedValues?.machine}
          dataset={
            values.operation && !viewField
              ? autocompleteOptions?.resource.filter((r) =>
                  resWithMachines?.[values.operation].machines.includes(r.value)
                )
              : autocompleteOptions?.resource
          }
        />
      </div>

      <div className="md:col-span-2 col-span-4">
        <Field
          type="number"
          name="workHours"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Óraszám" />}
          variant="standard"
          disabled={viewField}
          helperText={<FormErrorUI message={errors.workHours} />}
          InputLabelProps={{ shrink: values.workHours && values.workHours !== '' }}
        />
      </div>
      <div className="col-span-6 ">
        <Field
          type="radio"
          name="type"
          as={RadioGroupUI}
          row
          label={<FormLabelUI text="Típus" />}
          helperText={<FormErrorUI message={errors.type} />}
          disabled={viewField}
          value={values.type}
          option={LeaderReportTypeList}
        />
      </div>
      <div className="col-span-6 ">
        <Field
          type="radio"
          name="shift"
          as={RadioGroupUI}
          row
          label={<FormLabelUI text="Műszak" />}
          helperText={<FormErrorUI message={errors.shift} />}
          disabled={viewField}
          value={values.shift}
          option={LeaderReportShiftList}
        />
      </div>
      <div className="col-span-6 ">
        <Field
          type="radio"
          name="activity"
          component={CheckboxGroupSingleSelectUI}
          onChange={(value) => {
            setFieldValue('activity', value).then(() => {
              validateField('activity');
            });
          }}
          row
          label={<FormLabelUI text="Tevékenység" />}
          helperText={<FormErrorUI message={errors.activity} />}
          disabled={viewField}
          value={values.activity}
          option={LeaderReportActivityList}
        />
      </div>
    </div>
  );
};

export default SapShiftLeaderReportFields;
