import { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';

import { dataGridPremiumColumns, generateColumnsFromDynProps } from '../../utils/helper';
import crmTaskService from '../../service/crmTask.service';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';

const CrmTaskReports = ({ crmTaskState }) => {
  const {
    setRefetchTable,
    setSelectedValues,
    viewField,
    operatorsDisabled,
    refetchTable,
    rowSelectionModel,
    setRowSelectionModel,
    autoCompleteOptions,
    dynProps,
  } = crmTaskState;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'name', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    // @TODO táblázat se mutassa a selected sort
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    crmTaskService.getTasks(gridState).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'prefixedId',
        headerName: 'Azonosító',
        type: 'string',
      },
      {
        field: 'name',
        headerName: 'Esemény neve',
        type: 'string',
      },
      {
        field: 'startDate',
        headerName: 'Kezdő időpont',
        type: 'date',
        valueGetter: (_data, row) => {
          return new Date(row.startDate);
        },
        renderCell: (data) => {
          return dayjs(data.row.startDate).format('YYYY.MM.DD HH:mm');
        },
      },
      {
        field: 'endDate',
        headerName: 'Befejező időpont',
        type: 'date',
        valueGetter: (_data, row) => {
          return new Date(row.endDate);
        },
        renderCell: (data) => {
          return dayjs(data.row.endDate).format('YYYY.MM.DD HH:mm');
        },
      },
      {
        field: 'projectId',
        headerName: 'Projekt',
        type: 'singleSelect',
        sortable: false,
        renderCell: (params) => {
          return params.row.project?.projectName;
        },
        valueOptions: autoCompleteOptions.project,
      },
      {
        field: 'companyId',
        headerName: 'Cég',
        type: 'singleSelect',
        sortable: false,
        renderCell: (params) => {
          return params.row.company?.companyName;
        },
        valueOptions: autoCompleteOptions.company,
      },
      {
        field: 'typeId',
        headerName: 'Esemény típusa',
        type: 'singleSelect',
        sortable: false,
        renderCell: (params) => {
          return params.row.taskType?.itemName;
        },
        valueOptions: autoCompleteOptions.type,
      },
      {
        field: 'statusId',
        headerName: 'Státusz',
        type: 'singleSelect',
        sortable: false,
        renderCell: (params) => {
          return params.row.status?.itemName;
        },
        valueOptions: autoCompleteOptions.status,
      },
      {
        field: 'responsiblePersonId',
        headerName: 'Felelős',
        type: 'singleSelect',
        sortable: false,
        renderCell: (params) => {
          return params.row.responsiblePerson?.userName;
        },
        valueOptions: autoCompleteOptions.user,
      },
      {
        field: 'createdBy',
        headerName: 'Tulajdonos',
        type: 'singleSelect',
        sortable: false,
        renderCell: (params) => {
          return params.row.user?.userName;
        },
        valueOptions: autoCompleteOptions.user,
      },
      ...generateColumnsFromDynProps(dynProps ?? []),
    ]);
  }, [autoCompleteOptions, dynProps]);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.crmTask}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      refetch={{ refetchTable, setRefetchTable }}
      rowCount={reports.rowCount}
      columns={[...columns]}
      onRowClick={(rowData) => {
        if (!viewField) return;
        setSelectedValues(rowData.row);
      }}
      paginationAndfilterMode="server"
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      disableRowSelectionOnClick={!viewField}
    />
  );
};

export default CrmTaskReports;
