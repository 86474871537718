import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid-premium';
import ListIcon from '@mui/icons-material/List';
import { Button } from '@mui/material';

import { dataGridPremiumColumns } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import sapShiftLeaderReportService from '../../service/sapShiftLeaderReport.service';
import {
  LeaderReportActivityList,
  LeaderReportShiftList,
  LeaderReportTypeList,
} from '../../config/sapShiftLeaderReport';
import AttendanceDialog from './AttendanceDialog';

const CustomToolbar = ({ openDialog }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Button
        size="small"
        sx={{ ml: 'auto' }}
        startIcon={<ListIcon />}
        onClick={() => {
          openDialog();
        }}
      >
        Jelenlét
      </Button>
    </GridToolbarContainer>
  );
};

const SapShiftLeaderReportReports = ({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  autocompleteOptions,
  user,
}) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [attendanceDialog, setAttendanceDialog] = useState(false);

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'date', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    const gs = { ...gridState, userId: user.userId };
    sapShiftLeaderReportService.getSapShiftLeaderReports(gs).then((data) => {
      setReports(data);
    });
  };
  const columns = dataGridPremiumColumns([
    {
      field: 'date',
      type: 'date',
      headerName: 'Dátum',
      valueGetter: (_data, row) => {
        return new Date(row.date);
      },
      renderCell: (data) => {
        return dayjs(data.row.date).format('YYYY.MM.DD');
      },
      isRequired: true,
    },
    {
      field: 'project',
      type: 'singleSelect',
      headerName: 'Projekt',
      valueOptions: autocompleteOptions.project,
      renderCell: (data) => {
        return data.row.projectData?.Project;
      },
    },
    {
      field: 'operation',
      type: 'singleSelect',
      headerName: 'Művelet',
      valueOptions: autocompleteOptions.operation,
    },
    {
      field: 'operationNote',
      type: 'string',
      headerName: 'Művelet megj.',
    },
    {
      field: 'machine',
      type: 'singleSelect',
      headerName: 'Gép',
      valueOptions: autocompleteOptions.resource,
    },
    {
      field: 'workerId',
      type: 'singleSelect',
      headerName: 'Dolgozó',
      valueOptions: autocompleteOptions.employee,
    },
    {
      field: 'workHours',
      type: 'number',
      headerName: 'Óraszám',
      filterable: false,
    },

    {
      field: 'type',
      type: 'singleSelect',
      headerName: 'Típus',
      valueOptions: LeaderReportTypeList,
    },
    {
      field: 'shift',
      type: 'singleSelect',
      headerName: 'Műszak',
      valueOptions: LeaderReportShiftList,
    },
    {
      field: 'activity',
      type: 'singleSelect',
      headerName: 'Tevékenység',
      valueOptions: LeaderReportActivityList,
    },
  ]);

  const openDialog = () => {
    setAttendanceDialog(true);
  };

  return (
    <>
      <DatagridPremiumUi
        datagridIdentifier={DataGridIdentifiers.SapShiftLeaderReport}
        datagridInitialState={initialState}
        dataLoader={getList}
        rows={reports.rows}
        refetch={{ refetchTable, setRefetchTable }}
        rowCount={reports.rowCount}
        columns={[...columns]}
        onRowClick={(rowData) => {
          if (!viewField) return;
          setSelectedValues(rowData.row);
        }}
        paginationAndfilterMode="server"
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick={!viewField}
        slots={{
          toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            openDialog,
          },
        }}
      />
      <AttendanceDialog
        show={attendanceDialog}
        onHide={() => setAttendanceDialog(false)}
        autocompleteOptions={autocompleteOptions}
      />
    </>
  );
};

export default SapShiftLeaderReportReports;
