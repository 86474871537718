import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import masterDataService from '../../service/masterData.service';
import { generateAutocompleteItems } from '../../utils/helper';
import usePages from '../../context/PageContext';
import useMenus from '../../context/MenuContext';
import useUsers from '../../context/UsersContext';
import useParam from '../../context/ParamContext';
import { useLoader } from '../../provider/LoaderProvider';
import productionService from '../../service/production.service';
import jobMonitorService from '../../service/jobMonitor.service';

import { AutoCompleteSelectUI, DebouncedInputUI, FormLabelUI, SwitchUI } from '../Interface';
import JobMonitorCard from './JobMonitorCard';
import ProductDialog from './ProductDialog';

export const JobMonitorPage = () => {
  const navigate = useNavigate();
  const { getUser, user } = useUsers();
  const { getParam, params } = useParam();
  const { showLoader, hideLoader } = useLoader();
  const { menus, getMenus, getMenuSubMenuId, getSubMenuNamesByUrls } = useMenus();
  const { setPages } = usePages();
  const [prodOperations, setProdOperations] = useState({ rows: [], rowCount: 0 });
  const [submenu, setSubmenu] = useState(null);
  const [previousJobs, setPreviousJobs] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [production, setProduction] = useState({});
  const [productDialogIsOpen, setProductDialogIsOpen] = useState(false);
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    resourceOne: [],
    operations: [],
  });
  const [query, setQuery] = useState({
    filter: {
      filterModel: {
        items: [],
        logicOperator: 'and',
      },
    },
    sorting: {
      sortModel: [],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  useEffect(() => {
    getMenus();
    getParam();
    if (!Object.keys(user).length) getUser();
  }, []);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (submenu) {
      initDefaultValues();
    }
  }, [submenu]);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('production');
    setSubmenu(submenuId);
  }, [menus]);

  useEffect(() => {
    updateQueryForStatusId();
  }, [previousJobs, params]);

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['productionreport']);
  }, [menus]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceOneToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .operationToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          resourceOne: resp[0],
          operations: resp[1],
        };

        const acOptions = {
          resourceOne: generateAutocompleteItems(aData.resourceOne, 'name', 'id'),
          operations: generateAutocompleteItems(aData.operations, 'operationName', 'operationId'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {});
  };

  const updateQueryForStatusId = () => {
    if (params && params.JOBMONITOR_STATUSES) {
      const statusConfig = JSON.parse(params.JOBMONITOR_STATUSES);
      const statusIds = previousJobs ? statusConfig.forPreviousWork : statusConfig.noPreviousWork;
      setQuery((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          filterModel: {
            ...prev.filter.filterModel,
            items: [
              ...prev.filter.filterModel.items.filter((item) => item.field !== 'statusId'),
              { field: 'statusId', operator: 'isAnyOf', value: statusIds },
            ],
          },
        },
        pagination: {
          paginationModel: {
            pageSize: prev.pagination.paginationModel.pageSize,
            page: 0,
          },
        },
      }));
    }
  };

  const getList = () => {
    showLoader();
    jobMonitorService
      .getProductionOperations(query)
      .then((data) => {
        setProdOperations((prev) => ({
          rows: query.pagination.paginationModel.page === 0 ? data.rows : [...prev.rows, ...data.rows],
          rowCount: data.rowCount,
        }));
        setHasMore(prodOperations.rows.length < data.rowCount);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const updateQuery = (newFilterItems) => {
    setQuery((prev) => ({
      ...prev,
      filter: {
        filterModel: {
          items: newFilterItems,
        },
      },
      pagination: {
        paginationModel: {
          pageSize: prev.pagination.paginationModel.pageSize,
          page: 0,
        },
      },
    }));
  };

  const handleFilterChange = (field, value) => {
    updateQuery([
      ...query.filter.filterModel.items.filter((item) => item.field !== field),
      ...(value ? [{ field, operator: 'is', value }] : []),
    ]);
  };

  const handleNavigate = (prodOperationId) => {
    setPages({ subMenuName: submenuNames });
    navigate(`/app/productionReport/${prodOperationId}`);
  };

  const fetchMoreData = () => {
    setQuery((prev) => ({
      ...prev,
      pagination: {
        paginationModel: {
          ...prev.pagination.paginationModel,
          page: prev.pagination.paginationModel.page + 1,
        },
      },
    }));
  };

  const getProductionById = (id) => {
    productionService.getProductionById(id).then((data) => {
      setProduction(data);
      setProductDialogIsOpen(true);
    });
  };

  return (
    <div className="bg-[#CDDFF7] min-h-full px-6">
      <div className="flex justify-between gap-5 flex-wrap">
        <div className="flex gap-5 flex-wrap">
          <div className="w-[250px]">
            <AutoCompleteSelectUI
              label={<FormLabelUI text="Művelet kereső" />}
              dataset={autocompleteOptions.operations}
              onChange={(_e, newVal, reason) =>
                handleFilterChange('operationId', reason === 'clear' ? null : newVal?.value)
              }
              selectedValue={query.filter.filterModel.items.find((item) => item.field === 'operationId')?.value}
            />
          </div>
          <div className="w-[250px]">
            <AutoCompleteSelectUI
              label={<FormLabelUI text="Erőforrás kereső" />}
              dataset={autocompleteOptions.resourceOne}
              onChange={(_e, newVal, reason) =>
                handleFilterChange('resourceOneId', reason === 'clear' ? null : newVal?.value)
              }
              selectedValue={query.filter.filterModel.items.find((item) => item.field === 'resourceOneId')?.value}
            />
          </div>
        </div>
        <div className="flex gap-5 flex-wrap">
          <div className="w-[250px]">
            <DebouncedInputUI
              label="Keresés a gyártásban"
              debounceMs={800}
              setQuickFilterSearchValue={(newVal) => handleFilterChange('workingNumber', newVal)}
              quickFilterSearchValue={
                query.filter.filterModel.items.find((item) => item.field === 'workingNumber')?.value
              }
            />
          </div>
          <div className="pt-4">
            <SwitchUI label="Korábbi JOB-ok" checked={previousJobs} onClick={() => setPreviousJobs(!previousJobs)} />
          </div>
        </div>
      </div>
      <InfiniteScroll dataLength={prodOperations.rows.length} next={fetchMoreData} hasMore={hasMore}>
        {prodOperations?.rows.length > 0 &&
          prodOperations.rows.map((operation) => (
            <JobMonitorCard
              key={operation.id}
              data={operation}
              handleNavigate={handleNavigate}
              getProductionById={getProductionById}
            />
          ))}
      </InfiniteScroll>
      <ProductDialog
        open={productDialogIsOpen}
        handleClose={() => setProductDialogIsOpen(false)}
        production={production}
      />
    </div>
  );
};
