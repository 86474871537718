import React, { useState } from 'react';
import { Typography } from '@material-tailwind/react';

import sapResourcePlannerService from '../../../service/sapResourcePlanner.service';
import { SelectUI, ButtonUI } from '../../Interface';
import { DependencyTypes } from '../../../config/DependencyTypes';

const DependecyListSelectedOpPanel = ({
  parent,
  selectedOperationState,
  selectedDependencyState,
  getSapDependencyList,
}) => {
  const { selectedOperation, setSelectedOperation } = selectedOperationState;
  const { selectedDependency, setSelectedDependency } = selectedDependencyState;
  const [selectedType, setSelectedType] = useState(DependencyTypes.FS);

  const handleSave = () => {
    if (!Object.keys(selectedDependency).length) {
      const insData = {
        parentDocEntry: parent.DocEntry,
        parentLineNum: parent.LineNum,
        docEntry: selectedOperation.DocEntry,
        lineNum: selectedOperation.LineNum,
        type: selectedType,
      };
      sapResourcePlannerService.createSapProductionDependency(insData).then(() => getSapDependencyList());
    } else {
      const updData = {
        id: selectedDependency.id,
        type: selectedType,
      };
      sapResourcePlannerService.updateSapProductionDependency(updData).then(() => getSapDependencyList());
    }
  };

  const handleDelete = () => {
    sapResourcePlannerService
      .deleteSapProductionDependency({ id: selectedDependency.id })
      .then(() => getSapDependencyList());
  };

  return (
    <>
      <div className="col-span-1 flex justify-center mt-5">
        <Typography className="mb-3">
          <h4 className="font-semibold">GYU</h4>
          <p className="text-gray-700">{selectedOperation.DocNum}.</p>
        </Typography>
      </div>
      <div className="col-span-1 flex justify-center mt-5">
        <Typography className="mb-3">
          <h4 className="font-semibold">Project</h4>
          <p className="text-gray-700">{selectedOperation.Project}.</p>
        </Typography>
      </div>
      <div className="col-span-1 flex justify-center mt-5">
        <Typography className="mb-3">
          <h4 className="font-semibold">Művelet</h4>
          <p className="text-gray-700">{selectedOperation.U_QNT_RAJZSZAM}.</p>
        </Typography>
      </div>
      <div className="col-span-1 flex justify-center mt-5">
        <Typography className="mb-3">
          <h4 className="font-semibold">Gép</h4>
          <p className="text-gray-700">{selectedOperation.U_QNT_GEP}.</p>
        </Typography>
      </div>
      <div className="col-span-1 flex justify-center ">
        <SelectUI
          label={'Típus'}
          option={Object.values(DependencyTypes).map((val) => ({ label: val, value: val }))}
          value={selectedType}
          defaultValue={DependencyTypes.FS}
          onChange={(e) => setSelectedType(e.target.value)}
        />
      </div>
      <div className="col-span-3 flex justify-center  grid-cols-2 gap-x-4 mt-2">
        <div className="col-span-1 ">
          <ButtonUI className="bg-success" text="Mentés" onClick={() => handleSave()} />
        </div>
        <div className="col-span-1  ">
          {Object.keys(selectedDependency).length > 0 && (
            <ButtonUI className="bg-danger" text="Törlés" onClick={() => handleDelete()} />
          )}
        </div>
        <div className="col-span-1  ">
          <ButtonUI
            className="bg-warning"
            text="Mégsem"
            onClick={() => {
              setSelectedOperation({});
              setSelectedDependency({});
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DependecyListSelectedOpPanel;
