import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Typography } from '@material-tailwind/react';
import { DatePickerUI, InputUI, TimePickerUi, SelectUI, ButtonUI } from '../../Interface';
import sapResourcePlannerService from '../../../service/sapResourcePlanner.service';
import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';
import { isEmpty } from 'lodash';
import {
  validateDependencies,
  collectRelatedRecords,
  collectRelatedRecordsData,
  mapDependencies,
} from '../relationHelper';

const Details = ({ task, getProductionOrders, productionOrdersWithSapProDependencies, updateBatch }) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const {
    id,
    ItemCode,
    PlannedQty,
    DueDate,
    U_QNT_RAJZSZAM,
    ProdName,
    FinishPlannedQty,
    COMBINED_START,
    COMBINED_END,
    U_QNT_PLANSTATUS,
    Project,
    AdditQty,
    U_QNT_MEGJ,
  } = task;

  const [errorState, setErrorState] = useState({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  });

  const [formState, setFormState] = useState({
    start: null,
    end: null,
    AdditionalQuantity: 0,
    U_QNT_PLANSTATUS: null,
  });

  useEffect(() => {
    setFormState({
      start: dayjs(COMBINED_START).utc(),
      end: dayjs(COMBINED_END).utc(),
      AdditionalQuantity: AdditQty,
      U_QNT_PLANSTATUS: U_QNT_PLANSTATUS,
    });
  }, [task]);

  const update = () => {
    const startDate = dayjs(formState.start).toISOString();
    const endDate = dayjs(formState.end).toISOString();

    let originalTask = productionOrdersWithSapProDependencies.find((f) => f.id === task.id);

    const different = dayjs(formState.end).diff(originalTask.end, 'm');

    const updatedEventData = {
      id,
      startDate,
      endDate,
      different: different,
      AdditionalQuantity: formState.AdditionalQuantity,
      U_QNT_PLANSTATUS: formState.U_QNT_PLANSTATUS,
    };

    const relatedIds = collectRelatedRecords(productionOrdersWithSapProDependencies, id);
    const filteredDepStructure = relatedIds
      .map((_id) => productionOrdersWithSapProDependencies.find((item) => item.id === _id))
      .filter((item) => item !== undefined);
    const vTasks = mapDependencies(filteredDepStructure);

    originalTask.eventData.AdditQty = formState.AdditionalQuantity;

    const relatedRecordsData = collectRelatedRecordsData(productionOrdersWithSapProDependencies, id);

    const checkData = [];

    if (!isEmpty(relatedRecordsData)) {
      relatedRecordsData.forEach((e) => {
        const findedVData = vTasks.find((v) => v.itemId === e.id);

        if (!e.isParent) {
          checkData.push({
            ...findedVData,
            _end: dayjs(e._end).add(different, 'm').toDate(),
            _start: dayjs(e._start).add(different, 'm').toDate(),
          });
        } else {
          checkData.push({
            ...findedVData,
          });
        }
      });
    }

    let vTask = vTasks.find((v) => v.itemId === id);
    const updatedTask = {
      ...vTask,
      _end: formState.end,
      _start: formState.start,
    };
    checkData.push(updatedTask);

    const checkObj = [
      {
        task: updatedTask,
        start: formState.start,
        end: formState.end,
        different: different,
      },
    ];

    const parent = originalTask.dependencies.find((f) => f.parent)?.parent;
    if (parent) {
      const parentId = `${parent.docEntry}_${parent.lineNum}`;
      const parentOrderData = filteredDepStructure.find((f) => f.id === parentId);
      originalTask.parent = parentOrderData;
    }

    const valid = validateDependencies(checkObj, checkData, originalTask);

    if (valid) {
      updateBatch(updatedEventData);
    }
  };

  return (
    <div className="grid-cols-4 gap-x-4 gap-y-2 mx-2 md:grid min-h-[350px]">
      <Typography className="mb-3">
        <h4 className="font-semibold">Művelet</h4>
        <p className="text-gray-700">{ItemCode || 'N/A'}.</p>
      </Typography>
      <Typography className="mb-3">
        <h4 className="font-semibold">Tervezett idő</h4>
        <p className="text-gray-700">{PlannedQty || 'N/A'}.</p>
      </Typography>
      <Typography className="mb-3">
        <h4 className="font-semibold">Megjegyzés</h4>
        <p className="text-gray-700">{U_QNT_MEGJ || 'N/A'}.</p>
      </Typography>
      <Typography className="mb-3">
        <h4 className="font-semibold">Esedékesség</h4>
        <p className="text-gray-700">{dayjs(DueDate).format('YYYY.MM.DD.') || 'N/A'}.</p>
      </Typography>
      <Typography className="mb-3">
        <h4 className="font-semibold">Késztermék</h4>
        <p className="text-gray-800">{U_QNT_RAJZSZAM || 'N/A'}</p>
      </Typography>
      <Typography className="mb-3">
        <h4 className="font-semibold">Megnevezés</h4>
        <p className="text-gray-800">{ProdName || 'N/A'}</p>
      </Typography>
      <Typography className="mb-3">
        <h4 className="font-semibold">Késztermék mennyisége</h4>
        <p className="text-gray-800">{FinishPlannedQty || 'N/A'}</p>
      </Typography>
      <Typography className="mb-3">
        <h4 className="font-semibold">Projekt</h4>
        <p className="text-gray-800">{Project || 'N/A'}</p>
      </Typography>
      <Typography className="mb-3">
        <h4 className="font-semibold">Kezdő dátum, időpont</h4>

        <div className="grid grid-cols-2 gap-x-2 ">
          <div className="col-span-1 ">
            <DatePickerUI
              name="startDate"
              value={formState.start}
              onChange={(newDate) => {
                setFormState((prev) => ({ ...prev, start: dayjs(newDate) }));
              }}
            />
          </div>
          <div className="col-span-1 ">
            <TimePickerUi
              name="startTime"
              value={formState.start}
              onChange={(newDate) => {
                setFormState((prev) => ({ ...prev, start: dayjs(newDate) }));
              }}
            />
          </div>
        </div>
      </Typography>
      <Typography className="mb-3">
        <h4 className="font-semibold">Záró dátum, időpont</h4>
        <div className="grid grid-cols-2 gap-x-2 ">
          <div className="col-span-1 ">
            <DatePickerUI
              name="endDate"
              value={formState.end}
              disabled
              onChange={(newDate) => {
                setFormState((prev) => ({ ...prev, end: dayjs(newDate).utc() }));
              }}
            />
          </div>
          <div className="col-span-1 ">
            <TimePickerUi
              name="endTime"
              value={formState.end}
              disabled
              onChange={(newDate) => {
                setFormState((prev) => ({ ...prev, end: dayjs(newDate).utc() }));
              }}
            />
          </div>
        </div>
      </Typography>

      <Typography className="mb-3">
        <h4 className="font-semibold">Várakozási idő</h4>
        <InputUI
          name="input"
          type="number"
          variant="standard"
          step={1}
          value={formState.AdditionalQuantity}
          onChange={(e) => {
            setFormState((prev) => ({ ...prev, AdditionalQuantity: e.target.value }));
          }}
        />
      </Typography>
      <Typography className="mb-3">
        <h4 className="font-semibold">Státusz</h4>
        <SelectUI
          option={['BETERVEZETT', 'KEZDO']}
          value={formState.U_QNT_PLANSTATUS}
          onChange={(e) => {
            setFormState((prev) => ({ ...prev, U_QNT_PLANSTATUS: e.target.value }));
          }}
        />
      </Typography>
      <Typography className="mb-3">
        <ButtonUI text="Mentés" className="bg-success" type="button" onClick={() => update()} />
      </Typography>
    </div>
  );
};

export default Details;
