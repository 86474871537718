import { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';

import { dataGridPremiumColumns } from '../../utils/helper';
import simpletaskService from '../../service/simpletask.service';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';

const SimpleTaskReports = ({ simpleTaskState }) => {
  const {
    setRefetchTable,
    setSelectedValues,
    viewField,
    operatorsDisabled,
    refetchTable,
    params,
    autocompleteOptions,
  } = simpleTaskState;
  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'simpleTaskName', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  const getList = (gridState) => {
    simpletaskService.getGridTasks(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'prefixedId',
        headerName: 'Azonosító',
        type: 'string',
        sortable: false,
        isRequired: true,
        filterable: false,
      },
      {
        field: 'startDate',
        type: 'date',
        headerName: 'Kezdő időpont',
        valueGetter: (_data, row) => {
          return new Date(row.startDate);
        },
        renderCell: (data) => {
          return dayjs(data.row.startDate).format('YYYY.MM.DD HH:mm');
        },
      },
      {
        field: 'endDate',
        type: 'date',
        headerName: 'Befejező időpont',
        valueGetter: (_data, row) => {
          return new Date(row.endDate);
        },
        renderCell: (data) => {
          return dayjs(data.row.endDate).format('YYYY.MM.DD HH:mm');
        },
      },
      {
        field: 'simpleTaskName',
        headerName: 'Feladat',
        type: 'string',
        isRequired: true,
      },
      ...(params.SIMPLETASK_PROJECT_FIELD === 'Y'
        ? [
            {
              field: 'projectId',
              headerName: 'Projekt',
              type: 'singleSelect',
              sortable: false,
              renderCell: (data) => {
                return data.row.project?.projectName;
              },
              valueOptions: autocompleteOptions.project,
            },
          ]
        : []),
      {
        field: 'userId',
        headerName: 'Tulajdonos',
        type: 'singleSelect',
        sortable: false,
        renderCell: (params) => {
          return params.row.user?.userName;
        },
        valueOptions: autocompleteOptions.user,
      },
      {
        field: 'resposiblePersonId',
        headerName: 'Felelős',
        type: 'singleSelect',
        sortable: false,
        renderCell: (params) => {
          return params.row.responsiblePerson?.userName;
        },
        valueOptions: autocompleteOptions.user,
      },
      {
        field: 'preparedness',
        headerName: '%',
        type: 'number',
        sortable: false,
        renderCell: (params) => {
          const preparedness = params.row.preparedness ?? '';
          return `${preparedness}%`;
        },
      },
      ...(params.SIMPLETASK_RESOURCE_FIELD === 'Y'
        ? [
            {
              field: 'machineId',
              headerName: 'Erőforrás',
              type: 'singleSelect',
              sortable: false,
              renderCell: (params) => {
                return params.row.machine?.machineName;
              },
              valueOptions: autocompleteOptions.machine,
            },
          ]
        : []),
      {
        field: 'priorityId',
        headerName: 'Prioritás',
        type: 'singleSelect',
        sortable: false,
        renderCell: (params) => {
          return params.row.priority?.itemName;
        },
        valueOptions: autocompleteOptions.priority,
      },
      {
        field: 'statusId',
        headerName: 'Státusz',
        type: 'singleSelect',
        sortable: false,
        renderCell: (params) => {
          return params.row.status?.itemName;
        },
        valueOptions: autocompleteOptions.status,
      },
      ...(params.SIMPLETASK_COMPANY_LOCATION_FIELD === 'Y'
        ? [
            {
              field: 'companyLocationId',
              headerName: 'Telephely',
              type: 'singleSelect',
              sortable: false,
              renderCell: (params) => {
                return params.row.companyLocation?.name;
              },
              valueOptions: autocompleteOptions.companyLocation,
            },
          ]
        : []),
      {
        field: 'simpleTaskTypeId',
        headerName: 'Feladat típusa',
        type: 'singleSelect',
        sortable: false,
        renderCell: (params) => {
          return params.row.taskType?.itemName;
        },
        valueOptions: autocompleteOptions.type,
      },
      ...(params.SIMPLETASK_RESOURCE_ONE_FIELD === 'Y'
        ? [
            {
              field: 'resourceOneId',
              headerName: 'Dolgozó',
              type: 'singleSelect',
              sortable: false,
              renderCell: (params) => {
                return params.row.resourceOne?.name;
              },
              valueOptions: autocompleteOptions.resourceOne,
            },
          ]
        : []),
    ]);
  }, [autocompleteOptions]);

  return (
    <>
      {Object.keys(autocompleteOptions).length > 0 && (
        <DatagridPremiumUi
          datagridIdentifier={DataGridIdentifiers.simpleTaskReports}
          datagridInitialState={initialState}
          dataLoader={getList}
          rows={reports.rows}
          refetch={{ refetchTable, setRefetchTable }}
          rowCount={reports.rowCount}
          columns={[...columns]}
          onRowClick={(rowData) => {
            if (!viewField) return;
            setSelectedValues(rowData.row);
          }}
          paginationAndfilterMode="server"
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          disableRowSelectionOnClick={!viewField}
        />
      )}
    </>
  );
};

export default SimpleTaskReports;
