import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, CheckboxUI } from '../Interface';

export const CountryFields = ({ viewField, selectedValues, addField }) => {
  const { values, errors } = useFormikContext();

  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5">
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="code"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Ország kódja" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.code} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.code && values.code !== '' }}
        />
      </div>

      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Ország neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name && values.name !== '' }}
        />
      </div>

      <div className="md:col-span-2 col-span-4">
        <Field
          name="euMember"
          type="checkbox"
          as={CheckboxUI}
          fullWidth
          label={<FormLabelUI text="EU tag" />}
          disabled={viewField}
        />
      </div>

      <div className="md:col-span-2 col-span-4">
        <Field
          name="active"
          type="checkbox"
          as={CheckboxUI}
          fullWidth
          label={<FormLabelUI text="Aktív" />}
          disabled={viewField}
        />
      </div>
    </div>
  );
};

export default CountryFields;
