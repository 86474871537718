import dayjs from 'dayjs';

/**
 * Copies the hour and minute from the sourceDate to the targetDate.
 *
 * @param {string|Date|dayjs.Dayjs} sourceDate - The date from which to copy the hour and minute.
 * Can be a string, Date object, or a dayjs object.
 * @param {string|Date|dayjs.Dayjs} targetDate - The date to which the hour and minute will be applied.
 * Can be a string, Date object, or a dayjs object.
 * @returns {dayjs.Dayjs} - A new dayjs object with the same date as targetDate but with the hour and minute from sourceDate.
 */
export const copyHourMinute = (sourceDate, targetDate) => {
  const sDate = dayjs(sourceDate);
  const tDate = dayjs(targetDate);
  const hour = sDate.hour();
  const minute = sDate.minute();

  return tDate.hour(hour).minute(minute);
};
